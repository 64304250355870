/**
 * @flow
 */
import produce from 'immer';
import {
  LOGIN_LOADING_HIDE,
  LOGIN_LOADING_SHOW,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_LOADING_HIDE,
  LOGOUT_SUCCESS,
  SET_HEADER_ERROR,
  // GET_API_KEY,
  SET_HEADER_SUCCESS,
} from '../../constrants/reducers';

// import {string} from 'prop-types';

export type authProps = {
  auth: any,
  authenticate: boolean,
  headers: any,
  error: boolean,
  loading: boolean,
  errorMessage: any,
};

const initState: authProps = {
  auth: {},
  authenticate: false,
  error: false,
  errorMessage: null,
  loading: false,
  headers: {
    'Content-Type': 'application/json'
  },
};

const auth = (state: authProps = initState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        draft.authenticate = false;
        break;
      case LOGOUT_LOADING_HIDE:
        return (state = initState);
      case SET_HEADER_SUCCESS:
        draft.headers['Api-Key'] = action.payload;
        break;
      case SET_HEADER_ERROR:
        draft.errorMessage = action.error;
        break;
      case LOGIN_USER_SUCCESS:
        draft.auth = action.payload;
        draft.authenticate = true;
        draft.error = false;
        draft.errorMessage = '';
        break;
      case LOGIN_USER_ERROR:
        draft.error = true;
        draft.errorMessage = 'El usuario o password están incorrectos';
        break;
      case LOGIN_LOADING_SHOW:
        draft.loading = true;
        break;
      case LOGIN_LOADING_HIDE:
        draft.loading = false;
        break;
      case 'CLEAR_ERROR_MESSAGE':
        draft.error = false;
        draft.errorMessage = '';
        break;
      default:
        return state;
    }
  });
export default auth;
