import {Checkbox, FormControl, InputLabel, styled} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkIdentityTE} from 'src/Data/Client';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import canReturn from 'src/containers/onboardingPhone/canReturn';
import {setStep} from 'src/store/actions/Steps.Action';
import {
  editFormRequest,
  evalutionService,
  getActivityTypes,
  getApc,
  getUpdateTE,
  logsOnboarding,
  getApcPrepaid,
} from 'src/store/actions/requestCard.actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {
  GENDER_TYPE,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
  SYSTEM,
} from 'src/utils/Constants';
import ModalProcessing from '../Modals/ModalProcessing';
import {TermsAndConditionsAPC} from '../TermsAndConditionsAPC';
import SCREENS from '../screens';
import {InputArrow} from './InputArrow';
import {MyMenuItem, SelectComponent} from './styles';
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'auto',
}));
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  overflowX: 'hidden',
  overflowY: 'hidden',
}));
const ContainerInfo = styled('div')(() => ({
  height: '100%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
  },
}));

const DivTitle = styled('div')(() => ({
  width: '70%',
  margin: '10vh auto 2vh 15vh',
  padding: '0 5%',
  h1: {
    textAlign: 'left !important',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    margin: '7vh auto 0.5vh auto',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    margin: '7vh auto 10vh auto',
    textAlign: 'center',
    h1: {
      textAlign: 'center !important',
    },
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '7vh auto 10vh auto',
    marginBottom: '25px',
    width: '100%',
    textAlign: 'center',
  },
}));
const DivSubTitle = styled('div')(() => ({
  width: '70%',
  margin: '1vh auto 2vh 15vh',
  padding: '0 5%',
  [BreakPointTheme.breakpoints.down('lg')]: {
    margin: '1vh auto 0.5vh auto',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    margin: '1vh auto 0.5vh auto',
    textAlign: 'center',
    h1: {
      textAlign: 'center !important',
    },
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '1vh auto 2vh auto',
    marginBottom: '25px',
    width: '100%',
    textAlign: 'center',
  },
}));

const FormMain = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  width: '70%',
  margin: '0vh auto 0vh 15vh',
  padding: '0 5%',
  [BreakPointTheme.breakpoints.down('lg')]: {
    margin: '0vh auto',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    margin: '0vh auto',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '0vh auto',
    width: '100%',
  },
}));
const DropDownContainer = styled('div')(() => ({
  width: '100%',
  margin: '4rem auto 2rem',
}));
const FormControlContainer = styled(FormControl)(() => ({
  width: '80%',
  marginTop: '5px',
  [BreakPointTheme.breakpoints.down('xxl')]: {
    width: '50%',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '80%',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const CheckWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '0rem',
  width: '100%',
}));
export const DataReviewAPC = props => {
  // HOOKS - STATE
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Selecciona');
  const [activitySelected, setActivitySelected] = useState(false);
  // QUERIES
  const {
    activityTypes,
    origin,
    onboardingId,
    form,
    checkTe,
    base64,
  } = useSelector(state => state.RequestCardReducer);
  const formRequest = useSelector(state => state.RequestCardReducer.form);
  const {phoneForm, emailForm} = useSelector(state => state.RequestCardReducer);
  const options = activityTypes || [];
  // HANDLESS
  const handleShowTermsAndConditions = useCallback(
    state => {
      setShowTermsAndConditions(state);
    },
    [showTermsAndConditions],
  );

  const handleCheck = e => {
    const {checked} = e.target;
    setChecked(checked);
  };

  const handleChange = event => {
    const {value} = event.target;
    if (!value) return setSelectedOption('Selecciona');
    setSelectedOption(value);
    setActivitySelected(true);
    dispatch(editFormRequest({activityType: value}));
    return setSelectedOption(value);
  };
  const onSubmit = async () => {
    try {
      if (!checked) {
        return false;
      }
      setLoading(true);
      if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PREPAGO) {
        const createdBy = SELLER_ID_TYPE;
        const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58';
        let phones = [];
        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: createdBy,
            extension: null,
            lastModifiedBy: null,
          });
        }
        if (formRequest.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: formRequest.phone,
            isDefault: false,
            createdBy: createdBy,
          });
        }
        const formApc = {
          id: onboardingId,
          typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
          branchOfficeId: branchOfficeId,
          sellerId: SELLER_ID_TYPE,
          approvedBy: SELLER_ID_TYPE,
          approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
          genderId:
            formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
          countryId: formRequest.country,
          countryBirthId: formRequest.countryOfBirth,
          nationalityId: formRequest.nationality,
          systemId: SYSTEM.VOLCAN,
          identityNumber: formRequest.identity,
          firstName: formRequest.firstName,
          secondName: formRequest.secondName || null,
          firstSurname: formRequest.lastName,
          secondSurname: formRequest.secondLastName,
          dateOfBirth: formRequest.birthdate,
          IDExpirationDate: formRequest.IDExpirationDate,
          phones: phones,
          emails: [
            {
              typeEmail: 'PE',
              email: emailForm,
              isDefault: true,
              createdBy: createdBy,
            },
          ],
          images: [
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Frontal',
              link: formRequest.frontIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Reverso',
              link: formRequest.backIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
              description: 'Biometrico',
              link: formRequest.faceImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
          ],
          createdBy: 'CMF',
          LastModifiedBy: 'CMF',
        };
        if (origin === ORIGIN.PREPAGO) {
          dispatch(getApcPrepaid(formApc, onFinish.bind(this)));
        } else {
          dispatch(getApc(formApc, onFinish.bind(this)));
        }
      } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
        const createdBy = SELLER_ID_TYPE;
        const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58';
        const payload = {
          onboardingId: onboardingId,
          origin: origin,
          economicActivity: formRequest.activityType,
          lastModifiedDate: new Date(),
          createdBy: 'CMF',
        };
        let phones = [];

        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: createdBy,
            extension: null,
            lastModifiedBy: null,
          });
        }

        if (formRequest.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: formRequest.phone,
            isDefault: false,
            createdBy: createdBy,
          });
        }
        const payloadApc = {
          id: onboardingId,
          typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
          branchOfficeId: branchOfficeId,
          sellerId: SELLER_ID_TYPE,
          approvedBy: SELLER_ID_TYPE,
          approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
          genderId:
            formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
          countryId: formRequest.country,
          countryBirthId: formRequest.countryOfBirth,
          nationalityId: formRequest.nationality,
          systemId: SYSTEM_TYPES.CMF,
          identityNumber: formRequest.identity,
          firstName: formRequest.firstName,
          secondName: formRequest.secondName || null,
          firstSurname: formRequest.lastName,
          secondSurname: formRequest.secondLastName,
          dateOfBirth: formRequest.birthdate,
          IDExpirationDate: formRequest.IDExpirationDate,
          phones: phones,
          emails: [
            {
              typeEmail: 'PE',
              email: emailForm,
              isDefault: true,
              createdBy: createdBy,
            },
          ],
          images: [
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Frontal',
              link: formRequest.frontIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Reverso',
              link: formRequest.backIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
              description: 'Biometrico',
              link: formRequest.faceImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
          ],
          createdBy: 'CMF',
          LastModifiedBy: 'CMF',
        };
        dispatch(evalutionService(payloadApc, payload, onFinish.bind(this)));
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };
  const onSuccess = success => {
    setLoading(false);
    if (success) {
      dispatch(setStep(SCREENS.FinancialError));
      props.goToStep(SCREENS.FinancialError);
    }
  };
  const validateTE = async () => {
    if (checkTe) {
      try {
        const response = await checkIdentityTE(
          form?.identity,
          base64,
          origin,
          onboardingId,
          `${form?.firstName} ${form.lastName}`,
        );
        const {IdentityVerification} = JSON.parse(response.data.jsonAnswer);
        if (IdentityVerification) {
          if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
            props.goToStep(SCREENS.ApprovedRequest);
          } else if (
            origin === ORIGIN.PREAPROBADO ||
            origin === ORIGIN.PREPAGO
          ) {
            props.goToStep(SCREENS.ActividadLaboral);
          }
          setLoading(false);
        } else {
          dispatch(getUpdateTE(form?.identity, onSuccess.bind(this)));
          logsOnboarding(
            onboardingId,
            origin,
            'VALIDATE_IDENTITY_TE',
            form.identity,
            false,
          );
        }
      } catch (error) {
        setLoading(false);
        await logsOnboarding(
          onboardingId,
          origin,
          'RESPONSE_WS_TE',
          error.response,
          'CARD',
          false,
        );
      }
    } else {
      if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
        props.goToStep(SCREENS.ApprovedRequest);
      } else if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PREPAGO) {
        props.goToStep(SCREENS.ActividadLaboral);
      }
    }
  };
  const onFinish = async success => {
    if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (success === 3) {
        validateTE();
      } else if (success === 2) {
        props.goToStep(SCREENS.FinancialError);
      } else if (success === 1) {
        setLoading(false);
      }
    } else if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PREPAGO) {
      if (success === true) {
        validateTE();
      } else if (success === 2) {
        props.goToStep(SCREENS.FinancialError);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    dispatch(getActivityTypes());
  }, []);
  const selectedApc = () => {
    if (checked && activitySelected) return true;
  };
  if (showTermsAndConditions) {
    return (
      <TermsAndConditionsAPC
        handleShowTermsAndConditions={handleShowTermsAndConditions}
      />
    );
  }

  return (
    <Container>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      {loading && (
        <ModalProcessing
          isOpen={loading}
          headingText="Estamos procesando tu solicitud."
          bodyText={'Aguarda un momento.'}
        />
      )}
      <Wrapper>
        <ContainerInfo>
          <DivTitle>
            <TitleResponsiveOnboarding
              minSize={28}
              maxSize={45}
              color="#011E41"
              text="Aprueba la revisión de tus datos en la APC, firma electrónica y la creación en el programa de Puntos Gordos"
              fontFamily="Red Hat Text"
              textAlign="center"
            />
          </DivTitle>
          <DivSubTitle>
            <SubtitleResponsiveOnboarding
              minSize={20}
              maxSize={30}
              color="#000000"
              text="Es necesario indicar tu situación laboral y aceptar los términos y condiciones."
              fontFamily="Red Hat Text"
            />
          </DivSubTitle>
          <FormMain name="basic">
            <DropDownContainer id="DropDown">
              <SubtitleResponsiveOnboarding
                minSize={15}
                maxSize={20}
                color="#000000"
                text="Indica cuál es tu situación laboral"
                fontFamily="Red Hat Text"
              />
              <div className="my-2" />
              <FormControlContainer
                sx={{
                  width: '80%',
                  marginTop: '5px',
                }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    font: 'normal normal normal 20px/22px Red Hat Text',
                    color: '#707070',
                  }}>
                  Selecciona
                </InputLabel>
                <SelectComponent
                  label="Selecciona"
                  IconComponent={InputArrow}
                  sx={{
                    width: '100%',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                  }}
                  onChange={handleChange}
                  value={selectedOption}>
                  {options.map(option => (
                    <MyMenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MyMenuItem>
                  ))}
                </SelectComponent>
              </FormControlContainer>
            </DropDownContainer>
            <CheckWrapper>
              <Checkbox
                onClick={e => handleCheck(e)}
                value={checked}
                checked={checked}
                name="checked"
                sx={{
                  borderRadius: '4px',
                  color: '#026E18',
                  '&.Mui-checked': {
                    color: '#026E18',
                  },
                }}
              />
              <div>
                <label
                  style={{
                    color: '#000000',
                    fontFamily: 'Red Hat Text',
                    fontWeight: 'normal',
                    lineHeight: '25px',
                    fontSize: 'clamp(15px, 1.5vw, 18px)',
                  }}>
                  He leído y acepto{' '}
                  <span
                    tabIndex={0}
                    role="button"
                    className="text-[#015DC7] underline mx-1"
                    onClick={() => {
                      return handleShowTermsAndConditions(true);
                    }}>
                    Términos y Condiciones
                  </span>
                  de la evaluación crediticia
                </label>
              </div>
            </CheckWrapper>
          </FormMain>
        </ContainerInfo>
        <FooterOnboar
          back={props.canReturn ? canReturn(props, 5) : null}
          next={
            <ButtonNextOnboarding
              variant="onboarding"
              data-testid="next-apc"
              loading={loading}
              disabled={!selectedApc()}
              onClick={onSubmit.bind(this)}>
              Continuar
            </ButtonNextOnboarding>
          }
          screen={props.canReturn ? 'endline' : 'rightEnd'}
        />
      </Wrapper>
    </Container>
  );
};
