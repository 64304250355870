import { Box } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Cmf_Base, PointsApi } from 'src/Data/Api';
import CardsTypes from './CardsTypes';
import InfoBanner from './InfoBanner';
import './style.css';

export const CreditCard = () => {
  const { CurrentUser } = useSelector(state => state.AuthenticationReducer);
  const { selectedProduct } = useSelector(state => state.ActiveProductReducer);
  const dispatch = useDispatch();

  const handleClick = async e => {
    dispatch({
      type: 'SELECTED_PRODUCT',
      payload: e,
    });
    try {
      const response = await axios.get(
        `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
          e?.productAccount,
        )}&CreatedBy=${e?.CustomerId}`,
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
          },
        },
      );
      dispatch({
        type: 'GENERALVTC_ACCOUNT_STATEMENT_SUCCESS',
        payload: JSON.parse(response?.data.jsonAnswer),
      });
    } catch (error) {
      NotificationManager.error(
        'Ha ocurrido un error obteniendo información',
      );
    }
  };

  return (
    <Box
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
      }}>
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          gap: '8px',
          position: 'relative',
        }}>
        <CardsTypes onClick={() => handleClick(selectedProduct)} data={selectedProduct} />
        <InfoBanner />
      </Box>
    </Box>
  );
};
export default CreditCard;
