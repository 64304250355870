import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import { Helmet } from 'react-helmet';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import FooterComponent from 'src/components/Footer/footer.component';
import useResponsive from 'src/hooks/useResponsive';
import { ActiveProduct, getStatusChubb } from 'src/store/actions/ActiveProductAction';
import { resetMovementReduce } from 'src/store/actions/MovimientoAction';
import ModalError from '../Pages/ModalError';
import Login from '../auth/login.cmf';
import Header from './header';
import Nav from './nav';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#F9FAFB',
  [theme.breakpoints.down('md')]: {
    minHeight: '80%',
  },
}));

const Main = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  minHeight: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.background,
  paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStatusChubb());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();
  const { CurrentUser, Authenticated } = useSelector(
    state => state.AuthenticationReducer,
  );
  const [openModal, setOpenModal] = useState(false);
  const onIdle = () => {
    window.onbeforeunload = null;
    navigate('/');
    localStorage.removeItem('selectedIndex');
    dispatch({ type: 'RESET_ACTIVE_PRODUCT' });
    dispatch(resetMovementReduce());
    dispatch({
      type: 'CLEAN_LOGIN',
    });
  };
  useEffect(() => {
    if (!CurrentUser.error && CurrentUser.customerId) {
      dispatch(ActiveProduct(onSuccess));
    }
  }, [CurrentUser]);

  const onSuccess = success => {
    setOpenModal(success);
  };
  const timeout = 1000 * 120 * 1
  return (
    <React.Fragment>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      />

      {Authenticated === 3 ? (
        <Login route="reset" />
      ) : (
        <>
          <StyledRoot>
            <Helmet>
              <meta charSet="utf-8" />
              <title>CMF en línea</title>
            </Helmet>
            <Header onOpenNav={() => setOpen(true)} />
            <Nav openNav={open} onCloseNav={() => setOpen(false)} />
            <Main>
              <Outlet />
              {isDesktop && <FooterComponent />}
              {openModal ? <ModalError open={onSuccess} /> : <></>}
            </Main>
          </StyledRoot>

          {!isDesktop && <FooterComponent />}
        </>
      )}
    </React.Fragment>
  );
}
