import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'
import React from 'react'
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding'
import FooterOnboar from 'src/components/Footer/FooterOnboarding'
import { BreakPointTheme } from 'src/theme/breakpoints'
const WrapperDiv = styled('div')(({data}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '85%',
  alignSelf: 'center',
  marginBottom: '25px',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
    marginBottom: data === 'rcm' && '0',
  },
}))
const ButtonBack = styled(LoadingButton)(() => ({
  height: '7vh',
  maxHeight: "54px",
  width: '274px',
  font: 'normal normal normal 25px/26px Red Hat Text',
  fontSize: '25px',
  borderRadius: '38px',
  fontFamily: 'Red Hat Text',
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '244px',
    height: '3.5rem',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '200px',
    height: '3.5rem',
    fontSize: '1rem',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '200px',
    height: '56px',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '22vw',
    height: '60px',
    fontSize: '1.2rem',
    marginBottom: '2rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
    height: '54px',
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    height: '54px',
  },
}))
const LayoutForm = ({
  children,
  hasNextButton = false,
  hasBackButton = false,
  handleSubmit,
  handleBackSubmit,
  loading,
  props,
  data,
}) => {
  return (
    <>
      <WrapperDiv data={data}>{children}</WrapperDiv>
      <FooterOnboar
        back={
          hasBackButton ? (
            <ButtonBack
              loading={false}
              disabled={false}
              variant="onboardingOutline"
              onClick={handleBackSubmit}>
              Volver
            </ButtonBack>
          ) : null
        }
        next={
          hasNextButton ? (
            <ButtonNextOnboarding
              loading={loading}
              onClick={handleSubmit}
              variant="onboarding"
              disabled={loading}
              data-testid="btn-next-pep">
              Siguiente
            </ButtonNextOnboarding>
          ) : null
        }
        screen={hasBackButton ? 'endline' : 'rightEnd'}
      />
    </>
  )
}
export default LayoutForm
