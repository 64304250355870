import React from 'react'

function ButtonRender({label, className, handleButton}) {
  return (
    <div className="w-full lg:py-0 py-4">
      <button className={className} onClick={handleButton}>
        {label}
      </button>
    </div>
  )
}

export default ButtonRender
