import { notification } from 'antd'
import React, { useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import StepWizard from 'react-step-wizard'
import MaintenanceModal from '../../components/Modals/maintenanceModal'
import usePrevious from '../../components/Modals/usePrevious'
import {
  countryBirthTranslate,
  getBannerActive,
  getConfigOnboard,
  getCountries,
  getGenders,
  getNacionalizacion,
  getProvinces,
  getSettings,
  resetFormRequest
} from '../../store/actions/requestCard.actions'
import { DIALOG_TYPES, URL_PHONE } from '../../utils/Constants'
import ApcTerms from './ApcTerms'
import Congrats from './Congrats'
import EnrollDevice from './EnrollDevice'
import FilePDF from './FilePDF'
import FinancialError from './FinancialError'
import IdentityConfirmed from './IdentityConfirmed'
import NewCotizadorPhone from './NewCotizadorPhone'
import PersonalInformation from './PersonalInformation'
import PoliticalInfoForm from './PoliticalInfoForm'
import ProfessionalInformation from './ProfessionalInformation'
import RequestInRevision from './RequestInRevision'
import ResultEvaluation from './ResultEvaluation'
import ScanID from './ScanID'
import ThanksError from './ThanksError'
import VerifyInfo from './VerifyInfo'
import WelcomeScreenComponent from './WelcomePhone'
import './app.css'
import { SCREENS } from './screens'

const IndexPhone = () => {
  const {dialog} = useSelector(state => state.RequestCardReducer)
  const timeout = 1000 * 120 * 5
  // const { currentStep } = useSelector(state => state.step)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBannerActive())
    dispatch(resetFormRequest())
    dispatch({type: 'RESET_ONBOARDING_PHONE'})
    dispatch({type: 'CLEAN_DIALOG'})
    dispatch({type: 'SET_SELLER_IDENTIFICATION_CLEAN'})
    dispatch(getProvinces())
    dispatch(getNacionalizacion())
    dispatch(getGenders())
    dispatch(getCountries())
    dispatch(countryBirthTranslate())
    dispatch(getSettings())
    dispatch(getConfigOnboard())
    dispatch(resetFormRequest())
    dispatch({type: 'SET_URL_PATH', payload: window.location.pathname})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onIdle = () => {
    window.onbeforeunload = null
    window.location.href = `${window.location.origin}/${URL_PHONE}`
  }

  const prevCount = usePrevious(dialog)
  useEffect(() => {
    if (prevCount?.dialog?.id !== dialog?.id) {
      const {message: messageDialog, dialogType} = dialog

      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

      if (dialogType === DIALOG_TYPES.SUCCESS) {
        const openNotificationWithIcon = type => {
          notification[type]({
            message: 'Notificación',
            description: messageDialog,
            duration: 0,
          })
        }
        openNotificationWithIcon('success')
      } else {
        const openNotificationWithIcon = type => {
          notification[type]({
            message: 'Notificación',
            description: messageDialog,
            duration: 0,
          })
        }
        openNotificationWithIcon('error')
      }
    }
    // Crear un nuevo script element
    const script = document.createElement('script');
    // Establecer el src del script para apuntar al script externo
    script.src = 'https://cdn.seondf.com/js/v5/agent.js';
    script.async = true;
    // Añadir el script al body
    document.body.appendChild(script);
    // Opcionalmente, puedes limpiar al desmontar el componente
    return () => {
        document.body.removeChild(script);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog])
  window.onbeforeunload = function() {
    window.setTimeout(function() {
      window.location.href = this.props.path
    }, 0)
    return 'Alert!'
  }
  return (
    <>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      />
      <StepWizard
        isLazyMount={true}
        initialStep={SCREENS.WelcomeScreenComponent}
        transitions={{
          enterRight: 'fadeIn',
          enterLeft: 'fadeIn',
          exitRight: 'fadeOut',
          exitLeft: 'fadeOut',
        }}>
        <WelcomeScreenComponent canReturn={false} />
        <ScanID canReturn={true} />
        <IdentityConfirmed canReturn={true} />
        <VerifyInfo canReturn={true} />
        <PersonalInformation canReturn={true} />
        <ProfessionalInformation canReturn={true} />
        <PoliticalInfoForm canReturn={true} />
        <EnrollDevice canReturn={true} />
        <ApcTerms canReturn={true} />
        <NewCotizadorPhone canReturn={true} />
        <FilePDF canReturn={true} />
        <FinancialError canReturn={false} />
        <RequestInRevision canReturn={false} />
        <Congrats canReturn={false} />
        <ResultEvaluation canReturn={true} />
        <ThanksError canReturn={false} />
      </StepWizard>
      <MaintenanceModal origin="PHONE" />
    </>
  )
}

export default IndexPhone
