import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {makeStyles, styled} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Typography} from '@mui/material'

import {LoadingButton} from '@mui/lab'
import React from 'react'
import {BreakPointTheme} from 'src/theme/breakpoints'
import ModalHeaderButton from './ModalHeaderButton'
const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    margin: 'auto',
    width: '100%',
    [BreakPointTheme.breakpoints.up('xl')]: {
      width: '50%',
      maxWidth: '100%',
      height: 'auto',
    },
    [BreakPointTheme.breakpoints.up('lg1')]: {
      width: '50%',
      maxWidth: '100%',
      height: 'auto',
    },
    [BreakPointTheme.breakpoints.between('md', 'lg1')]: {
      width: '60%',
      maxWidth: '100%',
      height: 'auto',
    },
    [BreakPointTheme.breakpoints.down('sm1')]: {
      top: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  dialogTitle: {
    width: '80%',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    [BreakPointTheme.breakpoints.down('sm1')]: {
      paddingTop: theme.spacing(10),
      width: '95%',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0rem 0rem 3rem 0rem',
    [BreakPointTheme.breakpoints.up('md')]: {
      width: '95%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '6rem 0rem 3rem 0rem',
    },
    [BreakPointTheme.breakpoints.up('sm1')]: {
      width: '95%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '3rem 0rem 3rem 0rem',
    },
    [BreakPointTheme.breakpoints.down('sm1')]: {
      width: '95%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  buttonNext: {
    borderRadius: '9999px', // Bordes redondeados al máximo para un efecto circular
    padding: '18px 0', // Espaciado vertical, ajusta según necesidad
    width: '100%', // Ancho completo en móviles
    display: 'flex', // Asegura el diseño flex
    alignItems: 'center', // Centra los elementos verticalmente
    justifyContent: 'center', // Centra los elementos horizontalmente
    color: 'white',
    font: 'normal normal normal 25px/40px Red Hat Text',

    [BreakPointTheme.breakpoints.up('lg1')]: {
      font: 'normal normal normal 25px/40px Red Hat Text',
      width: '30%',
    },
    [BreakPointTheme.breakpoints.between('sm1', 'lg1')]: {
      font: 'normal normal normal 25px/25px Red Hat Text',
      width: '40%',
    },
    [BreakPointTheme.breakpoints.down('sm1')]: {
      width: '100%',
      height: '45px',
      font: 'normal normal normal 18px/35px Red Hat Text',
    },
  },
  responsiveText: {
    lineHeight: '40px',
    [BreakPointTheme.breakpoints.up('md')]: {
      lineHeight: '40px',
      padding: '0px 10px',
    },
    [BreakPointTheme.breakpoints.down('sm1')]: {
      lineHeight: '26px',
      padding: '0px 20px',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      lineHeight: '26px',
      padding: '0px',
    },
  },
}))
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5rem 0rem',
  [BreakPointTheme.breakpoints.up('lg1')]: {
    padding: '2rem 3.5rem',
    width: '85%',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    padding: '2rem 3.5rem',
    width: '85%',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    padding: '2rem 3.5rem',
    width: '95%',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    padding: '2rem 1.5rem',
    width: '80%',
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: '80%',
    padding: '2rem 0rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '2rem 0rem',
  },
  [BreakPointTheme.breakpoints.down('xsm')]: {
    width: '76%',
    padding: '2rem 0rem',
  },
}))
const ContainerButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  width: '90%',
  padding: '16px 0px',
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '90%',
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: '100%',
  },
}))
const ModalVerifyInformation = ({
  open,
  onHide,
  onConfirm,
  stepPage,
  onLoading,
}) => {
  const classes = useStyles()
  const fullScreen = useMediaQuery(BreakPointTheme.breakpoints.down('sm1'))
  return (
    <Dialog
      style={{zIndex: 100}}
      open={open}
      classes={{paper: classes.dialogWrapper}}
      fullScreen={fullScreen}>
      <ModalHeaderButton onHide={onHide} />
      <DialogContent className={classes.dialogContent}>
        <ContainerInfo>
          {stepPage === 1 ? (
            <Typography
              variant="verificationInfo"
              className={classes.responsiveText}>
              Ahora verificaremos tu identidad, por favor sigue las
              instrucciones paso a paso
            </Typography>
          ) : (
            <Typography
              variant="verificationInfo"
              className={classes.responsiveText}>
              Ahora podrás completar tu información personal y profesional
            </Typography>
          )}
        </ContainerInfo>
        <ContainerButton>
          <LoadingButton
            variant="contained"
            className={classes.buttonNext}
            onClick={() => {
              onConfirm()
            }}
            loading={onLoading}>
            Continuar
          </LoadingButton>
        </ContainerButton>
      </DialogContent>
    </Dialog>
  )
}

export default ModalVerifyInformation
