import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const InputArrow = () => {
    return (
        <div className='mx-2'>
            <KeyboardArrowDownIcon color='#011E41' sx={{
                color: '#011E41',
                cursor: 'pointer'
            }} />
        </div>
    )
}