import { styled } from '@material-ui/core/styles';
import { Box, Skeleton, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { Comun, formatoMoneda } from 'src/utils/Functions';
export const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Red Hat Text',
  [BreakPointTheme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));
export const DataBox = styled(Box)(() => ({
  borderRadius: '1rem',
  padding: '5px',
  margin: '5px',
  boxShadow: '2px 3px 3px 0 rgba(151, 154, 154, 0.66)',
  background: 'var(--specific-light-background-white, #FFF)',
  [BreakPointTheme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));
export const BoxInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '5px',
}));
export const BoxInfoProgress = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  border: '1px solid red',
}));

export const TextInline = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
export const TextInlineBwt = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));
export const TextBold = styled('p')(() => ({
  fontWeight: '700',
}));
export const TitleBold = styled('p')(() => ({
  fontWeight: '700',
  padding: '5px',
}));
export const TextUnderline = styled('p')(() => ({
  textDecoration: 'underline',
  color: '#3DAE2B',
}));
export const BoxWithIcon = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}));


const InformacionGeneral = () => {
  const { generalvtc, floatAmount, loadV } = useSelector(
    state => state.ActiveProductReducer,
  );
  const {
    SaldoFinalML,
    DisponibleML,
    FecMaxPago,
    LimCreditoML,
    PagoMinimoML,
    PagocontadoML,
  } = generalvtc;

  let fechaOriginal = moment(FecMaxPago, 'DD/MM/YYYY');

  let fechaModificada = fechaOriginal.subtract(1, 'months').set('date', 23);

  let formattedDate = fechaModificada.format('DD/MM/YYYY');

  const porcentajeConsumido = (SaldoFinalML / LimCreditoML) * 100;
  const porcentajeFlotantes = (floatAmount / LimCreditoML) * 100;
  const porcentajeDisponible = (DisponibleML / LimCreditoML) * 100;

  return (
    <ContainerBox>
      {loadV ? (
        <Skeleton variant="rectangular" width="100%">
          <div style={{ height: '15rem' }} />
        </Skeleton>
      ) : (
        <React.Fragment>
          <DataBox>
            <TitleBold>Resumen de la tarjeta</TitleBold>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Transacciones flotantes
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(floatAmount ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Límite total de la tarjeta
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(LimCreditoML ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Saldo Utilizado al {Comun(new Date())}
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(SaldoFinalML ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Disponible para compras
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(DisponibleML ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <BoxInfo>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '10px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  backgroundColor: '#e0e0e0',
                }}>
                <Tooltip
                  title={`Monto Consumido: ${formatoMoneda(
                    SaldoFinalML ?? 0,
                  )}`}>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: `${porcentajeConsumido}%`,
                      height: '100%',
                      backgroundColor: 'red',
                      transition: 'width 0.3s ease-in-out',
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={`Transacciones Flotantes: ${formatoMoneda(
                    floatAmount ?? 0,
                  )}`}>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: `${porcentajeConsumido}%`,
                      width: `${porcentajeFlotantes}%`,
                      height: '100%',
                      backgroundColor: 'yellow',
                      transition: 'width 0.3s ease-in-out',
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={`Disponible para Compras: ${formatoMoneda(
                    DisponibleML ?? 0,
                  )}`}>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: `${porcentajeConsumido + porcentajeFlotantes}%`,
                      width: `${porcentajeDisponible}%`,
                      height: '100%',
                      backgroundColor: 'green',
                      transition: 'width 0.3s ease-in-out',
                    }}
                  />
                </Tooltip>
              </Box>
            </BoxInfo>
          </DataBox>
          <DataBox>
            <TitleBold>Resumen del último estado de cuenta</TitleBold>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Pago mínimo
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(PagoMinimoML ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Fecha máxima de pago
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>
                    {FecMaxPago ? FecMaxPago : 'No disponible'}
                  </TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Fecha de corte
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold> {formattedDate}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <BoxInfo>
                  <p underline className="questions_titles">
                    Saldo total adeudado
                  </p>
                </BoxInfo>
                <BoxInfo>
                  <TextBold>{formatoMoneda(PagocontadoML ?? 0)}</TextBold>
                </BoxInfo>
              </Box>
            </Box>
          </DataBox>
        </React.Fragment>
      )}
    </ContainerBox>
  );
};

export default InformacionGeneral;
