import { MenuItem, Select, styled } from '@mui/material';

export const Section = styled('section')({
    background: "#F2F4F7 0% 0% no-repeat padding-box",
    height: "100%",
    padding: '100px 50px 50px 120px',
    width: "100%",
    '@media (max-width: 900px)': {
        padding: '30px',
    },
});

export const Heading = styled('h2')({
    color: "#011e41",
    fontFamily: "Red Hat Text",
    fontWeight: "600",
});

export const Paragraph = styled('p')({
    color: "#000000",
    fontFamily: "Red Hat Text",
    fontWeight: "normal",
    lineHeight: "30px",
});

export const Label = styled('label')({
    color: "#000000",
    font: "normal normal normal 18px/31px Red Hat Text",
    fontFamily: "Red Hat Text",
    fontWeight: "normal",
    lineHeight: "25px",
});

export const SelectComponent = styled(Select)({
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "none",
    borderRadius: "15px",
    color: " #707070",
    font: "normal normal normal 20px/22px Red Hat Text",
    height: "60px",
    outline: "none",
    width: "556px",
    '@media (max-width: 900px)': {
        width: '100%'
    }
});

export const MyMenuItem = styled(MenuItem)({
    color: '#6E6F71 !important',
    font: "normal normal normal 18px/34px Red Hat Text",
    height: '30px'
});

export const Button = styled('button')({
    background: "transparent linear-gradient(89deg, #399001 0%, #53A600 100%) 0% 0% no-repeat padding-box",
    borderRadius: "38px",
    color: "#FFFFFF",
    font: 'normal normal normal 25px/26px Red Hat Text',
    height: '76px',
    opacity: 1,
    width: '274px',
    '&:disabled': {
        background: 'grey',
        cursor: 'not-allowed',
        opacity: 0.7,
      },
});
