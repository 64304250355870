import {LoadingButton} from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useDispatch, useSelector} from 'react-redux';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import useForm from 'src/hooks/useForms';
import {setStep} from 'src/store/actions/Steps.Action';
import {
  editFormRequest,
  registerInitial,
  sendEmailToVerify,
  sendSmsToVerify,
  ValidateCustomerWithEmailAndPhonePrepaid,
} from 'src/store/actions/requestCard.actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {
  APP,
  BRANCH_OFFICES,
  MAX_ATTEMPTS,
  MAX_ATTEMPTS_MESSAGE,
  ONBOARDING_STATUS,
  SELLER_ID_TYPE,
  TIMER_SECONDS,
} from 'src/utils/Constants';
import {openNotificationWithIcon} from 'src/utils/Functions';
import * as Yup from 'yup';
import CheckIconBlue from '../../../assets/images/onboardingCredito/check.svg';
import {NumericInput, TextFieldComponent} from '../Component/FieldsForms';
import ModalValidationMail from '../Modals/ModalValidationMail';
import ModalValidationPhone from '../Modals/ModalValidationPhone';
import ModalVerifyInformation from '../Modals/ModalVerifyInformation';
import SCREENS from '../screens';
import {colorStyle, isEmail, isPhoneNumber} from './helpers';
import {ORIGIN} from 'src/utils/Constants';

const ContainerData = styled('div')(({theme}) => ({
  width: '100%',
  height: '100%',
}));
const Section = styled('section')(({theme}) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const ContainerVerification = styled('div')(({theme}) => ({
  width: '90%',
  margin: '30px auto',
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: 'auto',
    margin: '10px 1rem',
  },
}));
const FormControlInput = styled(FormControl)(() => ({
  width: '50%',
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '60%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: '100%',
    marginLeft: '0rem',
  },
}));
const ContainerButton = styled('div')(({theme}) => ({
  width: '100%',
  position: 'absolute',
  bottom: '0',
  right: '0',
}));
const ContainerMessage = styled('div')(({theme}) => ({
  width: '50%',
  height: '50px',
  backgroundColor: '#1D2939',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
  alignItems: 'center',

  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '60%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: '0rem',
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: '100%',
    marginLeft: '0rem',
  },
}));

const Label = styled('label')(({theme}) => ({
  margin: '1.25rem 0',
  font: 'normal normal 18px/35px Red Hat Text ',
  fontWeight: '500',
  letterSpacing: '0px',
  color: '#011E41',
  textAlign: 'center',
  opacity: 1,
}));

const Button = styled(LoadingButton)(({theme}) => ({
  width: '207px',
  height: '55px',
  borderRadius: '5px 50px 50px 5px',
  opacity: 1,
  color: '#FFFFFF',
  '@media (max-width: 1024px)': {
    height: '45px',
    width: '86px',
    fontSize: '16px',
  },
}));

const TextField = styled(TextFieldComponent)(({theme}) => ({
  outline: 'none !important',
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    border: 'none !important',
    borderRadius: '15px 5px 5px 15px',
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      padding: '20px',
      borderRadius: '15px 5px 5px 15px',
    },
    height: '100%',
    '& fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '15px 5px 5px 15px',
    },
    '&:hover fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '15px 5px 5px 15px',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '15px 5px 5px 15px',
    },
  },
}));

const Paragraph = styled('p')(({theme}) => ({
  color: '#ffffff',
}));

const initialData = {
  email: {
    name: 'email',
    label:
      'Ingresa tu correo electrónico y te enviaremos un código de confirmación',
    placeholder: 'Ingresa tu correo electrónico',
    errorMessages: 'Correo electrónico no válido',
    successMessage: 'Correo electrónico validado',
  },
  phone: {
    name: 'phone',
    label:
      'Ingresa tu número de celular y te enviaremos un código de confirmación',
    placeholder: 'Ingresa tu número de celular: (ejemplo: 66001122)',
    errorMessages: 'Número de celular no válido',
    successMessage: 'Número de celular validado',
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(initialData.email.errorMessages)
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      initialData.email.errorMessages,
    )
    .required(initialData.email.errorMessages),
  phone: Yup.string()
    .min(8, initialData.phone.errorMessages)
    .max(8, initialData.phone.errorMessages)
    .matches(/^6\d{7}$/, initialData.phone.errorMessages)
    .required(initialData.phone.errorMessages),
});

const VerificationForm1 = ({
  verificationStep = 0,
  setVerificationStep = () => {
    return;
  },
  className = '',
  props,
}) => {
  const dispatch = useDispatch();
  const modalValidateMailRef = useRef();
  const modalValidatePhoneRef = useRef();

  const {
    formData,
    getValue,
    handleChange,
    handleBlur,
    showError,
    getError,
    setOnChangeEvents,
    validateField,
  } = useForm(initialData, validationSchema);

  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(false);
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(false);
  const [sendInfo, setPayload] = useState([]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [disableNumberPhone, setdisableNumberPhone] = useState(false);
  //timer countdown to validation send code to twilio
  //state to Mail
  const [countClickMail, setCountClickMail] = useState(0);
  const [countdownMail, setCountdownMail] = useState(TIMER_SECONDS);
  const [isButtonDisabledMail, setIsButtonDisabledMail] = useState(false);
  const [finishTimerMail, setFinishTimerMail] = useState(false);
  const [intervalIdMail, setIntervalIdMail] = useState(null);
  //state to Phone
  const [countClickPhone, setCountClickPhone] = useState(0);
  const [countdownPhone, setCountdownPhone] = useState(TIMER_SECONDS);
  const [isButtonDisabledPhone, setIsButtonDisabledPhone] = useState(false);
  const [finishTimerPhone, setFinishTimerPhone] = useState(false);
  const [intervalIdPhone, setIntervalIdPhone] = useState(null);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  // functions to timer Mail

  const startTimerMail = timeToTimer => {
    setIsButtonDisabledMail(true);
    const intervalId = setInterval(() => {
      setCountdownMail(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          setIsButtonDisabledMail(false);
          setFinishTimerMail(true);
          setCountClickMail(0);
          setCountdownMail(timeToTimer);
          return timeToTimer;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    setIntervalIdMail(intervalId);
  };
  const initialTimerMail = () => {
    clearInterval(intervalIdMail);
    setCountdownMail(TIMER_SECONDS);
    setIsButtonDisabledMail(false);
    setFinishTimerMail(false);
  };
  const stopTimerMail = () => {
    clearInterval(intervalIdMail);
    setIsButtonDisabledMail(false);
    setFinishTimerMail(false);
  };
  //function to format time 00:00
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  //funcitions to timer phone
  const startTimerPhone = () => {
    setIsButtonDisabledPhone(true);
    const intervalId = setInterval(() => {
      setCountdownPhone(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          setIsButtonDisabledPhone(false);
          setFinishTimerPhone(true);
          setCountClickPhone(0);
          setCountdownMail(TIMER_SECONDS);
          return TIMER_SECONDS;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    setIntervalIdPhone(intervalId);
  };
  const initialTimerPhone = () => {
    clearInterval(intervalIdPhone);
    setCountdownPhone(TIMER_SECONDS);
    setIsButtonDisabledPhone(false);
    setFinishTimerPhone(false);
  };
  const stopTimerPhone = () => {
    clearInterval(intervalIdPhone);
    setIsButtonDisabledPhone(false);
    setFinishTimerPhone(false);
  };
  /**
   * Determines the appropriate style for the phone number field.
   * If the phone number is complete (at least 8 characters), it must start with '6' to apply the style.
   * If the phone number is not complete, it checks if the phone number is valid according to other criteria.
   * @returns {string} The style class based on the phone number's validity and criteria.
   */
  function colorPhoneStyle() {
    const phoneNumber = getValue('phone');
    // Check if the phone number starts with '6'.
    const startsWith6 = phoneNumber.startsWith('6');
    // Check if the phone number length is at least 8 characters.
    const isLengthValid = phoneNumber.length >= 8;

    // Determine if the conditions for styling are met.
    const shouldApplyStyle = isLengthValid
      ? (startsWith6 && phoneVerificationStatus) || isPhoneNumberValid
      : isPhoneNumberValid;
    return colorStyle(shouldApplyStyle);
  }
  // Example of usage
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [screenScan, setScreenScan] = useState(false);
  const {
    loadingSms,
    loadingEmail,
    routeCreateUser,
    errorMail,
    errorPhone,
    originSourceOnboarding,
  } = useSelector(state => {
    return state.RequestCardReducer;
  });
  const id = routeCreateUser?.newCode;

  const validateEmail = () => {
    if (!APP.fakeEmail) {
      dispatch(
        sendEmailToVerify(getValue('email'), () => {
          modalValidateMailRef.current.show();
        }),
      );
    } else {
      setEmailVerificationStatus(true);
      setIsEmailValidated(true);
      setIsEmailValid(false);
      initialTimerMail();
    }
  };
  const handleClickEmail = () => {
    setCountClickMail(countClickMail + 1);
    if (countClickMail < MAX_ATTEMPTS) {
      validateField('email', validateEmail);
    } else {
      setIsButtonDisabledMail(true);
      openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
    }
  };
  const setOnChangeEventsForm = useCallback(() => {
    const events = [
      {
        name: 'email',
        onChange: value => dispatch(editFormRequest({email: value})),
      },
      {
        name: 'phone',
        onChange: value => dispatch(editFormRequest({cellphone: value})),
      },
    ];
    setOnChangeEvents(events);
  });
  useEffect(() => {
    setOnChangeEventsForm();
  }, []);
  const handleClickPhone = () => {
    setCountClickPhone(countClickPhone + 1);
    if (countClickPhone < MAX_ATTEMPTS) {
      validateField('phone', validatePhone);
    } else {
      setIsButtonDisabledPhone(true);
      openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
    }
  };
  const validatePhone = () => {
    if (!APP.fakePhone) {
      dispatch(
        sendSmsToVerify(getValue('phone'), () => {
          modalValidatePhoneRef.current.show();
        }),
      );
    } else {
      setPhoneVerificationStatus(true);
      setIsPhoneValidated(true);
      setdisableNumberPhone(false);
      initialTimerPhone();
    }
  };
  const onConfirmModal = () => {
    setIsLoadingNext(true);
    dispatch({type: 'EMAIL', payload: formData.email.value});
    dispatch({type: 'PHONE', payload: formData.phone.value});
    dispatch(registerInitial(sendInfo, onFinish));
  };
  const onFinish = success => {
    setIsLoadingNext(false);
    if (success) {
      dispatch(setStep(SCREENS.ScanID));
      props.goToStep(SCREENS.ScanID);
    }
  };
  const onCheck = async () => {
    try {
      let phones = [];
      if (formData.phone.value) {
        phones.push({
          id: 1,
          typePhone: 'CE',
          area: '507',
          phone: formData.phone.value,
          isDefault: true,
          createdBy: SELLER_ID_TYPE,
          extension: null,
          lastModifiedBy: null,
        });
      }
      const payload = {
        action: 'CRECRE_UPDCED',
        istekky: true,
        OnboardingCreDTO: {
          id: id,
          statusId: ONBOARDING_STATUS.PREAPROBADO,
          phones: phones,
          branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          emails: [
            {
              typeEmail: 'PE',
              email: formData.email.value,
              isDefault: true,
              createdBy: SELLER_ID_TYPE,
              lastModifiedDate: new Date(),
              createdDate: new Date(),
            },
          ],
          createdBy: 'CMF',
          LastModifiedBy: 'CMF',
        },
      };
      const payloadPpaid = {
        id: id,
        statusId: ONBOARDING_STATUS.PREAPROBADO,
        branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        emails: [
          {
            Id: 0,
            TypeEmail: 'PE',
            Email: formData.email.value,
            IsDefault: true,
            CreatedBy: SELLER_ID_TYPE,
          },
        ],
        phones: phones,
        createdBy: 'CMF',
        LastModifiedBy: 'CMF',
        lastModifiedDate: new Date(),
        createdDate: new Date(),
        origin: ORIGIN.PREPAGO,
      };
      let payloadToSend =
        originSourceOnboarding === ORIGIN.PREPAGO ? payloadPpaid : payload;
      if (isEmailValidated && isPhoneValidated) {
        setPayload(payloadToSend);
        dispatch(
          ValidateCustomerWithEmailAndPhonePrepaid(
            formData.email.value,
            formData.phone.value,
            onComplete,
          ),
        );
      } else {
        NotificationManager.error(
          'Debes validar teléfono y email para continuar.',
        );
      }
    } catch (errorInfo) {
      return;
    }
  };

  const onComplete = success => {
    if (success === 1) {
      setScreenScan(true);
    } else if (success) {
      dispatch(setStep(SCREENS.PrepagoProductExist));
      props.goToStep(SCREENS.PrepagoProductExist);
    } else if (!success) {
      dispatch(setStep(SCREENS.PrepagoRedirectApp));
      props.goToStep(SCREENS.PrepagoRedirectApp);
    }
  };
  useEffect(() => {
    if (showError('email')) {
      setIsEmailValid(false);
      setIsEmailValidated(false);
    }
  }, [showError('email')]);
  useEffect(() => {
    if (showError('phone')) {
      setIsPhoneValidated(false);
      setIsPhoneNumberValid(false);
    }
  }, [showError('phone')]);

  /**
   * Maneja el cambio en el campo de teléfono.
   *
   * Si el teléfono ya ha sido verificado o es válido, restablece los estados relacionados.
   * Valida si el número de teléfono tiene la longitud adecuada y actualiza su estado.
   * Luego, maneja el cambio de valor para el campo de teléfono.
   *
   * @param {Object} e - El evento del input del teléfono.
   */
  function handlePhoneChange(e) {
    dispatch({type: 'SET_ERROR_PHONE', payload: false});
    const value = e.target.value;
    // Valida la longitud del número de teléfono.
    if (value !== '') {
      setIsPhoneNumberValid(isPhoneNumber(value));
      setdisableNumberPhone(isPhoneNumber(value));
      initialTimerPhone();
      setCountClickPhone(0);
    }
    // Restablece los estados si el teléfono está verificado o es válido.
    if (phoneVerificationStatus || isPhoneValidated) {
      setPhoneVerificationStatus(false);
      setIsPhoneNumberValid(false);
      setIsPhoneValidated(false);
    }
    // Maneja el cambio de valor para el campo de teléfono.
    handleChange('phone', value);
  }
  /**
   * Handles changes to the email input field.
   * Validates the email, updates the state, and resets verification status if needed.
   *
   * @param {Object} e - The event object from the input field.
   */
  function handleEmailChange(e) {
    dispatch({type: 'SET_ERROR_MAIL', payload: false});
    const emailValue = e.target.value;
    // Validates if the email is valid using the most recent value.
    setIsEmailValid(isEmail(emailValue));
    initialTimerMail();
    setCountClickMail(0);
    setIsButtonDisabledMail(false);
    // Resets states if the email is already verified.
    if (emailVerificationStatus) {
      setEmailVerificationStatus(false);
      setIsEmailValidated(false);
    }

    // Handles the change of value for the email field.
    handleChange('email', emailValue);
  }

  const disabledButtonPhone =
    phoneVerificationStatus || loadingSms || errorPhone || isButtonDisabledPhone
      ? '#D0D5DD 0% 0% no-repeat padding-box !important'
      : phoneVerificationStatus ||
        (getValue('phone').length >= 8 &&
          getValue('phone').startsWith('6') &&
          isPhoneNumberValid) ||
        disableNumberPhone
      ? 'transparent linear-gradient(270deg, #53A600 0%, #399001 100%) 0% 0% no-repeat !important'
      : '#D0D5DD 0% 0% no-repeat padding-box !important';

  const colorButtonPhone =
    (getValue('phone').length >= 8 && getValue('phone').startsWith('6')) ||
    disableNumberPhone
      ? colorPhoneStyle()
      : '#666B76 !important';

  const onHideModal = () => {
    setScreenScan(false);
  };
  return (
    <>
      <ModalValidationMail
        ref={modalValidateMailRef}
        email={formData.email.value}
        onConfirm={() => {
          setIsEmailValidated(true);
          setEmailVerificationStatus(true);
        }}
        startTimerMail={() => {
          startTimerMail();
        }}
        countdownMailFormat={formatTime(countdownMail)}
        initialTimerMail={() => {
          initialTimerMail();
        }}
        finishTimer={finishTimerMail}
        stopTimerMail={() => {
          stopTimerMail();
        }}
      />
      <ModalValidationPhone
        ref={modalValidatePhoneRef}
        phone={formData.phone.value}
        onConfirm={() => {
          setIsPhoneValidated(true);
          setPhoneVerificationStatus(true);
        }}
        startTimerPhone={() => {
          startTimerPhone();
        }}
        countdownPhoneFormat={formatTime(countdownPhone)}
        initialTimerPhone={() => {
          initialTimerPhone();
        }}
        finishTimer={finishTimerPhone}
        stopTimerPhone={() => {
          stopTimerPhone();
        }}
      />
      <ModalVerifyInformation
        open={screenScan}
        onHide={onHideModal}
        onConfirm={onConfirmModal}
        stepPage={1}
        onLoading={isLoadingNext}
      />
      <ContainerData>
        <Section>
          <ContainerVerification>
            <Label>{initialData.email.label}</Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
              <FormControlInput fullWidth error={showError('email')}>
                <TextField
                  autoComplete="off"
                  placeholder={initialData.email.placeholder}
                  sx={{
                    border: '1px solid #D0D5DD',
                    borderRadius: '15px 5px 5px 15px',
                    opacity: '1',
                    margin: '0px',
                  }}
                  value={getValue('email')}
                  onChange={handleEmailChange}
                  onBlur={() => handleBlur('email')}
                  InputProps={{
                    endAdornment: isEmailValidated && (
                      <InputAdornment position="end">
                        <img src={CheckIconBlue} alt="check" />
                      </InputAdornment>
                    ),
                  }}
                  error={showError('email')}
                />
                {showError('email') && (
                  <FormHelperText>{getError('email')}</FormHelperText>
                )}
              </FormControlInput>
              <Button
                disabled={isButtonDisabledMail || errorMail}
                loading={loadingEmail}
                sx={{
                  width: '207px',
                  background:
                    isEmailValid &&
                    !emailVerificationStatus &&
                    !loadingEmail &&
                    !errorMail &&
                    !isButtonDisabledMail
                      ? 'transparent linear-gradient(270deg, #53A600 0%, #399001 100%) 0% 0% no-repeat !important'
                      : '#D0D5DD 0% 0% no-repeat padding-box !important',
                  color:
                    emailVerificationStatus || isEmailValid
                      ? '#FFFFFF !important'
                      : '#666B76 !important',
                }}
                onClick={() => {
                  if (isEmailValid && !emailVerificationStatus) {
                    handleClickEmail();
                  }
                }}>
                Enviar
              </Button>
            </div>
            {isEmailValidated && (
              <ContainerMessage>
                <Paragraph style={{fontSize: '15px'}}>
                  {initialData.email.successMessage}
                </Paragraph>
              </ContainerMessage>
            )}
          </ContainerVerification>
          <ContainerVerification>
            <Label> {initialData.phone.label} </Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
              <FormControlInput fullWidth error={showError('phone')}>
                <TextField
                  autoComplete="off"
                  placeholder={initialData.phone.placeholder}
                  sx={{
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid #D0D5DD',
                    borderRadius: '15px 5px 5px 15px',
                    opacity: '1',
                    margin: '0px',
                  }}
                  value={getValue('phone')}
                  onChange={handlePhoneChange}
                  onBlur={() => handleBlur('phone')}
                  InputProps={{
                    inputComponent: NumericInput,
                    endAdornment: phoneVerificationStatus &&
                      getValue('phone').startsWith('6') && (
                        <InputAdornment position="end">
                          <img src={CheckIconBlue} alt="check" />
                        </InputAdornment>
                      ),
                  }}
                  error={showError('phone')}
                />
                {showError('phone') && (
                  <FormHelperText>{getError('phone')}</FormHelperText>
                )}
              </FormControlInput>
              <Button
                disabled={isButtonDisabledPhone || errorPhone}
                loading={loadingSms}
                sx={{
                  width: '207px',
                  background: disabledButtonPhone,
                  color: colorButtonPhone,
                }}
                onClick={() => {
                  if (isPhoneNumberValid && !isPhoneValidated) {
                    handleClickPhone();
                  }
                }}>
                Enviar
              </Button>
            </div>
            {isPhoneValidated && (
              <ContainerMessage c>
                <Paragraph style={{fontSize: '15px'}}>
                  {initialData.phone.successMessage}
                </Paragraph>
              </ContainerMessage>
            )}
          </ContainerVerification>
          <ContainerButton>
            {isEmailValidated && isPhoneValidated && (
              <FooterOnboar
                next={
                  <ButtonNextOnboarding variant="onboarding" onClick={onCheck}>
                    Continuar
                  </ButtonNextOnboarding>
                }
                screen="right"
              />
            )}
          </ContainerButton>
        </Section>
      </ContainerData>
    </>
  );
};

VerificationForm1.propTypes = {
  className: PropTypes.string,
  props: PropTypes.shape({
    goToStep: PropTypes.func,
  }),
  setVerificationStep: PropTypes.func,
  verificationStep: PropTypes.number,
};

export default VerificationForm1;
