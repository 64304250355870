import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { BASE_COLORS } from '../../../theme/constants';

const CardContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  background: BASE_COLORS.background,
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const CardAcciones = props => {
  const { selectedProduct } = useSelector(state => state.ActiveProductReducer);
  const CardButton = styled(LoadingButton)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: '20px',
    border: 'none',
    padding: '10px',
    margin: '10px',
    background: BASE_COLORS.white,
    backgroundColor: '#FFFFFF !important',
    cursor: 'pointer',
    height: '116px',
    width: '131px',
    [BreakPointTheme.breakpoints.down('sm')]: {
      display: 'flex',
      height: '120px',
      padding: '12px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flex: selectedProduct?.isCorporate ? 'none' : '1 0 0',
      borderRadius: '20px',
      background: 'var(--specific-light-background-white, #FFF)',
    },
    [BreakPointTheme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flex:selectedProduct?.isCorporate ? 'none' : '1 0 0',
      alignSelf: 'stretch',
      borderRadius: '20px',
      background: 'var(--specific-light-background-white, #FFF)',
    },
  }));
  return (
    <CardContainer>
      {props.loading ? (
        <CardButton
          disabled={props.disabled}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress size={24} />
        </CardButton>
      ) : (
        <CardButton onClick={props.onClick} disabled={props.disabled}>
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '52px',
              background: 'var(--specific-light-background-verde, #F1F8F3)',
            }}>
            <img
              src={props.image}
              alt="Default Alt"
              style={{
                height: '24px',
                width: '24px',
                color: '#026E18',
              }}
            />
          </Box>
          <Typography
            variant="H6"
            sx={{
              alignSelf: 'stretch',
              textAlign: 'start',
            }}>
            {props.text}
          </Typography>
        </CardButton>
      )}
    </CardContainer>
  );
};

export default CardAcciones;
