import { styled } from '@material-ui/core/styles';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  CantidadTotalEnPorcentaje,
  formatoMoneda,
  ocultarNumero,
} from 'src/utils/Functions';
import './style.css';

const Cicular = styled(CircularProgress)(({theme, type}) => ({
  color: theme.palette.common.white,
  width: '28px !important',
  position: 'absolute',
  '& .MuiCircularProgress': {
    backgroundSize: '100% 100%',
    border: '4px solid',
    borderColor: '#d0d5dd29',
    borderRadius: '14.25px',
    height: '28px',
    left: '1px',
    position: 'relative',
    top: '1px',
    width: '28px',
  },
}))
const CardsTypes = ({type, data}) => {
  const {selectedProduct, generalvtc, loadV  } = useSelector(
    state => state.ActiveProductReducer,
  )
  const imageCardBackground = data?.customerAccountCards[0]?.cardImage

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Función que actualiza el estado del ancho de la pantalla cuando cambia el tamaño de la ventana
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <>
      {loadV ? (
        <Skeleton
          variant="rectangular"
          width={282}
          height={160}
          sx={{
            borderRadius: '20px',
          }}
        />
      ) : (
        <Box
          type={type}
          sx={{
            backgroundImage: `url(${imageCardBackground})`,
            backgroundSize: 'cover',
            borderRadius: '20px',
            height: '170px',
            overflow: 'hidden',
            position: 'relative',
            width: '282px',
            [BreakPointTheme.breakpoints.down('sm')]: {
              width: `${windowWidth - 42}px`,
              height: '210px',
            },
          }}>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'inline-flex',
              gap: selectedProduct?.isCorporate ? '0px' : '8px',
              padding: selectedProduct?.isCorporate ? '22px 1vw' : '16px',
              position: 'absolute',
              top: '0',
              [BreakPointTheme.breakpoints.up('sm')]: {
                left: '116px',
              },
              [BreakPointTheme.breakpoints.down('sm')]: {
                right: 0,
                padding: selectedProduct?.isCorporate ? '27px 1vw' : '16px',
              },
            }}>
            <Box
              className="spinnerCard"
              sx={{position: 'relative', display: 'inline-flex'}}>
              <Cicular
                variant="determinate"
                thickness={6}
                value={CantidadTotalEnPorcentaje(
                  generalvtc?.DisponibleML,
                  generalvtc?.LimCreditoML,
                )}
              />
              <Cicular
                style={{
                  color: '#D0D5DD29',
                }}
                variant="determinate"
                thickness={6}
                value={100}
              />
            </Box>
            <div className="div">
              <Typography
                variant="moneyCard"
                sx={{
                  fontSize: data?.isCorporate ? '18px' : '20px',
                  color: '#FFF',
                }}>
                {formatoMoneda(generalvtc.DisponibleML ?? 0)}
              </Typography>
              <Typography variant="H4" sx={{color: '#FFFFFF8A'}}>
                Disponible
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              justifyContent: 'space-between',
              left: '-1px',
              padding: '16px',
              position: 'absolute',
              bottom: '5px',
              width: '282px',
              flexDirection: 'column',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}>
            {data?.isCorporate && (
              <Typography
                variant="H5"
                sx={{color: '#FFFFFF8A', fontSize: '12px'}}>
                {selectedProduct?.fullName}
                <br />
                {generalvtc?.Nombre}
              </Typography>
            )}
            <Typography color="#FFF" variant="textCard" sx={{fontSize: '18px'}}>
              {ocultarNumero(
                data?.isCorporate
                  ? data?.customerAccountCards[0]?.lastFourDigitCard
                  : data?.decryptedProductAccount,
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
export default CardsTypes
