import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import CongratsSign from 'src/containers/OnboardingCredito/CongratsSign/index';
import ContinueSigningContract from 'src/containers/OnboardingCredito/ContinueSigningContract';
import ComponentMain from 'src/containers/cmf_linea/Movimientos/ComponentMain';
import DashboardHome from 'src/containers/cmf_linea/Pages/DashboardHome';
import DetailsCardHome from 'src/containers/cmf_linea/Pages/DetailsCardHome';
import Reset from 'src/containers/cmf_linea/ResetPassword/resetPassword';
import Login from 'src/containers/cmf_linea/auth/login.cmf';
import Insurance from 'src/containers/cmf_linea/chubb/insurance';
import DetailsCard from 'src/containers/cmf_linea/pagar_cmf/DetailsCard';
import PagarHome from 'src/containers/cmf_linea/pagar_cmf/PagarHome';
import PromocionesCMF from 'src/containers/cmf_linea/promociones/promocionesCMF';
import ConfigCredentials from 'src/containers/cmf_linea/user_config/ConfigCredentials';
import SecurityProfile from 'src/containers/cmf_linea/user_config/SecurityProfile';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import NotFound from 'src/screens/404';
import OnboardingCredito from '../containers/OnboardingCredito';
import DashboardLayout from '../containers/cmf_linea/dashboard/index';
import OnboardingPhone from '../containers/onboardingPhone';
import CongratsPhone from '../containers/onboardingPhone/Congrats';
import FilePDContinue from '../containers/onboardingPhone/FilePDContinue';
import ProductExist from '../screens/ProductExist';

const checkSiteUrl = () => {
  if (!window.location.href.includes('/site')) {
    window.location.href = '/site';
  }
};

checkSiteUrl();
export default function App() {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {element: <Navigate to="/home" />, index: true},
        {path: '/home', element: <DashboardHome />},
        {path: '/home/details', element: <DetailsCardHome />},
        {path: '/home/promotions', element: <PromocionesCMF />},
        {path: '/home/products', element: <PagarHome />},
        {path: '/home/products/detailscard', element: <DetailsCard />},
        {path: '/home/Personal', element: <ConfigCredentials />},
        {path: '/home/Security', element: <SecurityProfile />},
        {path: '/home/movements', element: <ComponentMain />},
        {path: '/home/insurance', element: <Insurance />},
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '/reset-password',
      element: <Reset />,
    },
    {
      path: '/Personal',
      element: (
        <AuthGuard>
          <ConfigCredentials />
        </AuthGuard>
      ),
    },
    {
      path: '/Security',
      element: (
        <AuthGuard>
          <SecurityProfile />
        </AuthGuard>
      ),
    },
    {
      path: '/CardRequest',
      element: <OnboardingCredito />,
    },
    {
      path: '/c9pUQ4TnM3FYF9mxj1Bh2E06F8W1mX',
      element: <OnboardingPhone />,
    },
    {
      path: '/CongratsSign',
      element: <CongratsSign />,
    },
    {
      path: '/Signcontract/origin/:origin/onboardingId/:onboardingId',
      element: <ContinueSigningContract />,
    },
    {
      path: '/Signcontractphone/origin/:origin/onboardingId/:onboardingId',
      element: <FilePDContinue />,
    },
    {
      path: '/ProductExist',
      element: <ProductExist />,
    },
    {
      path: '/Congrats',
      element: <CongratsPhone />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  return routes
}
