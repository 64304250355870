import {combineReducers} from 'redux';
import step from './steps.reducer';
import auth from './auth.reducer';
import Loading from './Loading.reducer';
import language from './language.reducer';
import environment from './environment.reducer';
import financial from './financial.reducer';
import balance from './balance.reducer';
import paths from './path.reducer';
import pdfBalance from './pdf.reducer';
import tenantReducer from './tenants.reducer';
import cuentaReducer from './CuentaReducers';
import ActiveProductReducer from './ActiveProductreducer';
import EstadoDeCuentaReducer from './EstadoDeCuentaReducer';
import GeneralInformationReducer from './GeneralInformationReducer';
import MovimientoReducer from './MovimientoReducer';
import AuthenticationReducer from './LoginReducers';
import RequestCardReducer from './RequestCardReducer';
import KOReducer from './KO.Reducer';
import FactoringReducer from './Factoring.reducer';
import Phone from './phone.reducer'

export default combineReducers({
  step,
  auth,
  financial,
  Loading,
  language,
  environment,
  balance,
  paths,
  pdfBalance,
  tenantReducer,
  cuentaReducer,
  ActiveProductReducer,
  EstadoDeCuentaReducer,
  GeneralInformationReducer,
  MovimientoReducer,
  KOReducer,
  AuthenticationReducer,
  RequestCardReducer,
  FactoringReducer,
  Phone,
});
