export const SCREENS = {
  Welcome: 1,
  Recommendation: 2,
  VerifyMailPhone: 3,
  ScanID: 4,
  IdentityConfirmed: 5,
  ApcTerms: 6,
  ActividadLaboral: 7,
  ChubbScreen: 8,
  CreditApproved: 9,
  ReadAndSignPDF: 10,
  ErrorScreen: 11,
  FinancialError: 12,
  RequestInRevision: 13,
  ApprovedRequest: 14,
  ApprovedInsurance: 15,
  ProductExist: 16,
  IdentityMissingFields: 17,
  PrepagoProductExist: 18,
  PrepagoRequestInRevision: 19,
  PrepagoRedirectApp: 20,
  ReadPdfPrepago: 21,
  PrepagoProductStop: 22,
};
export default SCREENS;
