import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArticleIcon from '@mui/icons-material/Article';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PersonIcon from '@mui/icons-material/Person';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  Pagination,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cmf_Base, movementApi } from 'src/Data/Api';
import useResponsive from 'src/hooks/useResponsive';
import { generalAccountStatementvtc } from 'src/store/actions/ActiveProductAction';
import { resetMovementReduce } from 'src/store/actions/MovimientoAction';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  TransactionTypeDecorator,
  formatearFecha,
  getTransactionTypeDescription,
} from 'src/utils/Functions';
import formats from 'src/utils/formats';
import DownloadMovements from './downloadMovements';
import './style.css';

const ButtonMore = styled(Button)(() => ({
  display: 'flex',
  padding: '14px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
  borderRadius: '16px',
  background: 'var(--specific-light-background-10, #F9FAFB)',
  width: '100%',
}))

export function ObtenerIcono(valor) {
  switch (valor) {
    case 'Pagos':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
    case 'Envio':
      return (
        <PersonIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
    case 'COM': //Volcan
    case 'Compra':
      return (
        <LocalMallIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
    case 'Pagos - Acreditación por Remesa':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
    case 'Reverso de Compra':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
    default:
      return (
        <AttachMoneyIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      )
  }
}
export default function Movimientos({path, all, myData, clean, backMov}) {
  const {movements} = useSelector(state => state.MovimientoReducer)
  const isDesktop = useResponsive('up', 'md')
  const textoDownload = isDesktop ? 'Descargar estado de cuenta' : ''
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const [readDate, setDate] = useState({
    year: 0,
    month: 0,
    day: 0,
    moment: null,
  })
  const [loading, setLoading] = useState(false)
  const {selectedProduct, generalvtc} = useSelector(
    state => state.ActiveProductReducer,
  )
  const location = useLocation()

  // Ejemplo de validación basada en la ruta
  const isValidRoute = location.pathname === '/home/movements'

  const {CurrentUser} = useSelector(state => state.AuthenticationReducer)

  const navigate = useNavigate()

  const [mappedCount] = useState(0)

  // const itemsPerPage = !path ? 10 : 100
  const itemsPerPage = 10

  const [page, setPage] = useState(1)

  const [jsonExcel, setJson] = useState(false)

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const renderMovements = movements?.slice(
    (page - 1) * itemsPerPage,
    (page - 1) * itemsPerPage + itemsPerPage,
  )

  async function fetchData(year, month) {
    setLoading(true)
    setError(false)

    dispatch(resetMovementReduce())

    try {
      const fetchMovements = async tipe_mov => {
        const response = await axios.get(`${Cmf_Base}${movementApi}`, {
          params: {
            anyo: year,
            mes: month,
            cuenta: selectedProduct?.productAccount,
            CreatedBy: selectedProduct?.productAccount,
            tipo_mov: tipe_mov,
          },
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
          },
        })

        if (response.status !== 200) {
          throw new Error(
            `Solicitud fallida con código de estado ${response.status}`,
          )
        }

        return JSON.parse(response.data.jsonAnswer)
      }

      const movementsF = await fetchMovements('F')
      const movementsE = await fetchMovements('E')

      let totalMtoTraF = 0
      if (movementsF) {
        totalMtoTraF = movementsF.reduce(
          (sum, movement) => sum + movement.MtoTra,
          0,
        )
      }

      setJson(
        movementsF.concat(movementsE).sort((a, b) => b.Secuencia - a.Secuencia)
          .length > 0,
      )

      dispatch({
        type: 'MOVEMENT_STATUS_SUCCESS',
        payload: movementsF
          .concat(movementsE)
          .sort((a, b) => b.Secuencia - a.Secuencia),
      })

      dispatch({
        type: 'FLOAT_AMOUNT',
        payload: totalMtoTraF,
      })
    } catch (error) {
      if (
        error?.response?.data?.problemInternal === 'No existen movimientos.' ||
        (error?.response?.data?.problemInternal?.includes(
          'NO EXISTEN MOVIMIENTOS A CONSULTAR',
        ) &&
          !error?.response?.data?.complete)
      ) {
        setJson(false)
        dispatch({
          type: 'MOVEMENT_STATUS_SUCCESS',
          payload: [],
        })
      } else {
        setError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (path === 'home') {
      fetchData(0, 0)
    } else if (path === false && !clean && backMov !== 1) {
      const daysInMonth = moment().daysInMonth()
      const year = 0
      const month = 0
      setDate({
        day: daysInMonth,
        year,
        month,
        moment: null,
      })
      dispatch({
        type: 'MOVEMENT_STATUS_SUCCESS',
        payload: null,
      })
    } else if (clean) {
      setJson(false)
      const daysInMonth = moment().daysInMonth()
      const year = 0
      const month = 0
      setDate({
        day: daysInMonth,
        year,
        month,
        moment: null,
      })
      dispatch({
        type: 'MOVEMENT_STATUS_SUCCESS',
        payload: null,
      })
    }
  }, [selectedProduct])

  useEffect(() => {
    if (isValidRoute) {
      dispatch(generalAccountStatementvtc())
    }
  }, [selectedProduct])

  useEffect(() => {
    if (movements) {
      dispatch({
        type: 'MOVEMENT_STATUS_SUCCESS',
        payload: movements,
      })
      setJson(true)
    }
  }, [])

  useEffect(() => {
    if (isValidRoute && backMov === 1) {
      dispatch(resetMovementReduce())
    }
  }, [isValidRoute])

  const handleDateChange = newDate => {
    dispatch(resetMovementReduce())
    if (!newDate) return null
    localStorage.setItem('date', newDate)
    const daysInMonth = moment(newDate).daysInMonth()
    const momentDate = moment(newDate)
    const selectedMonth = momentDate.format('M')
    const selectedYear = momentDate.format('YYYY')

    fetchData(selectedYear, selectedMonth)
    setDate({
      year: selectedYear,
      month: selectedMonth,
      day: daysInMonth,
      moment: momentDate,
    })
  }

  const reloadMovement = () => {
    if (path === 'home') {
      fetchData(0, 0)
    } else {
      fetchData(readDate.year, readDate.month)
    }
  }

  const listStyle = {
    maxHeight: !path ? '450px' : '350px',
    overflowY: 'auto',
  }

  const handleDateChanges = value => {
    if (!path) {
      myData(value)
    }
  }

  const renderElements = () => {
    let moventsdata = renderMovements !== null ? renderMovements : movements
    if (!moventsdata || (moventsdata && moventsdata.length === 0)) {
      return (
        <>
          {error ? (
            <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
              <Button
                variant="contained"
                data-testid="reintentar"
                startIcon={<ReplayIcon />}
                onClick={reloadMovement}>
                Reintentar
              </Button>
            </Paper>
          ) : (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '24px',
                backgroundColor: '#fff',
                width: '100%',
                height: '238.86px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '50%',
                  backgroundColor: '#f5fbee',
                  width: '6rem',
                  height: '93.03px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <ArticleIcon
                  sx={{
                    width: '100%',
                    height: '48px',
                    color: '#E2F9C8',
                  }}
                />
              </Box>
              <br />
              <Typography variant="H6">
                {readDate.month === 0 && path !== 'home'
                  ? 'Selecciona el mes que deseas revisar'
                  : path === 'home'
                  ? 'No se han encontrado movimientos recientes'
                  : 'No tiene movimientos para el mes seleccionado'}
              </Typography>
            </Box>
          )}
        </>
      )
    }
    const renderMovementView =
      //  all
      //   ? movements
      //   :
      moventsdata && moventsdata.slice(mappedCount, mappedCount + itemsPerPage)

    if (renderMovementView === undefined) return null
    return renderMovementView.map(value => {
      if (value === undefined) return null
      return (
        <>
          <ListItem
            key={value}
            secondaryAction={
              <Box
                sx={{
                  textAlign: 'end',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography variant="H6">
                  {`${
                    TransactionTypeDecorator[value?.Transaccion]
                  }${formats.currency(value?.MtoTra)}`}
                </Typography>
                <Typography variant="H4">
                  {formatearFecha(value?.FechaOrigen, 'MM/DD/YYYY')}
                </Typography>
              </Box>
            }
            onClick={() => {
              handleDateChanges(value)
            }}
            disablePadding>
            <ListItemButton
              sx={{
                paddingTop: 2.5,
                paddingBottom: 2.5,
                borderRadius: 4,
              }}>
              <ListItemAvatar
                sx={{
                  minWidth: '40px',
                  display: 'flex',
                  width: '40px',
                  height: '40px',
                  marginRight: 2,
                  padding: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '52px',
                  background: '#F9FAFB',
                }}>
                {ObtenerIcono(value?.Transaccion)}
              </ListItemAvatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '60%',
                  },
                }}>
                <Typography variant="H6">{value?.Descripcion}</Typography>
                <Typography variant="H4">
                  {getTransactionTypeDescription(value?.Transaccion)}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
          {/* <div> */}
        </>
      )
    })
  }

  const handleShowMore = () => {
    navigate('/home/movements')
  }

  return (
    <>
      {all && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
            marginBottom: 2,
          }}>
          <DatePicker
            value={readDate.moment}
            label={readDate.month === 0 ? 'Seleccionar mes' : 'Filtrar'}
            openTo="month"
            views={['year', 'month']}
            onChange={handleDateChange}
            disableFuture
            sx={{
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '70%',
              },
            }}
          />

          <DownloadMovements
            texto={textoDownload}
            style={{
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '30%',
              },
            }}
            dates={readDate}
            origin={selectedProduct?.isCorporate}
            data={movements}
            existJson={jsonExcel}
          />
        </Box>
      )}
      <List
        dense
        style={listStyle}
        sx={{
          width: '100%',
          maxWidth: '100%',
        }}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{height: '15rem'}} />
          </Skeleton>
        ) : (
          <>{renderElements()}</>
        )}
      </List>

      {path !== 'home' && (
        <>
          {path !== false && (
            <>
              {mappedCount < movements?.length && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '8px',
                      justifyContent: 'space-between',
                      textTransform: 'none',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      borderTop: '1px solid #EAECF0',
                      background: '#FFF',
                    }}>
                    <ButtonMore
                      onClick={handleShowMore}
                      endIcon={
                        <Box
                          sx={{
                            display: 'flex',
                            width: '36px',
                            height: '36px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '10px',
                          }}>
                          <ArrowForwardIcon
                            sx={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </Box>
                      }
                      sx={{
                        color: 'rgba(16, 24, 40, 0.80)',
                        textAlign: 'right',
                        fontFamily: 'Red Hat Text',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px /* 150% */',
                        letterSpacing: '-0.16px',
                      }}>
                      <Typography
                        variant="buttonStyle"
                        sx={{color: '#101828CC', textTransform: 'none'}}>
                        Ver todos los movimientos
                      </Typography>
                    </ButtonMore>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
      {!loading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '8px',
          }}>
          {movements && movements.length > 0 && (
            <>
              <Pagination
                sx={{
                  color: '#229A16',
                }}
                count={Math.ceil(movements?.length / itemsPerPage)}
                onChange={handlePageChange}
                page={page}
              />
            </>
          )}
        </Box>
      )}
    </>
  )
}
