import InfoIcon from '@mui/icons-material/Info';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useForm from 'src/hooks/useForms';
import {UpdateDataMatrix} from 'src/store/actions/KO.Actions';
import {
  UpdateEndPreap,
  countryBirthTranslate,
  editFormRequest,
  UpdateEndPrepaid,
} from 'src/store/actions/requestCard.actions.js';
import * as Yup from 'yup';
import ArrowCommon from '../../components/ArrowCommon';
import {ORIGIN, SELLER_ID_TYPE} from '../../utils/Constants.js';
import {fieldTypeOptions} from '../CardRequest/FormRequest.config';
import {
  MyBox,
  MyInputLabel,
  SelectComponent,
  TextFieldComponent,
} from './Component/FieldsForms';
import LayoutForm from './Layout/LayoutForm';
import ModalProcessing from './Modals/ModalProcessing';
import SCREENS from './screens';

const MyRadio = styled(Radio)(({theme}) => ({
  color: '#C8C5C5',
  borderRadius: '20px',
  height: '30px',

  '&.Mui-checked': {
    color: '#026E18',
  },
}));

const MyFormLabel = styled(FormLabel)(({theme}) => ({
  font: 'normal normal normal 14px/18px Red Hat Text!important',
  color: '#000000',

  '@media (max-width: 1024px)': {
    font: 'normal normal normal 16px/20px Red Hat Text',
  },
  '&.Mui-focused': {
    color: '#000000',
  },
}));

const MyOption = styled(Typography)(({theme}) => ({
  font: 'normal normal normal 18px/22px Red Hat Text!important',
  color: '#707070',
  '@media (max-width: 1024px)': {
    font: 'normal normal normal 16px/22px Red Hat Text!important',
  },
}));

const initialData = {
  politicallyExposed: {
    name: 'politicallyExposed',
    label: '¿Eres una persona expuesta políticamente?',
    errorMessage: 'Campo requerido',
  },
  position: {
    name: 'position',
    label: '¿Qué cargo desempeñas?',
    showValidations: [
      {
        name: 'politicallyExposed',
        value: '1',
        defaultValue: '',
        isNumber: true,
      },
    ],
    errorMessage: 'Posición requerida',
  },
  relationPep: {
    name: 'relationPep',
    label:
      '¿Es familiar cercano o estrecho colaborador de una Persona Expuesta Políticamente?',
    errorMessage: 'Campo requerido',
  },
  namePolitically: {
    name: 'companyPhone',
    label: '¿Cuál es el nombre de la persona expuesta políticamente?',
    showValidations: [
      {name: 'relationPep', value: '1', defaultValue: '', isNumber: true},
    ],
    errorMessage: 'Nombre requerido',
  },
  positionPolitically: {
    name: 'companyAddress',
    label: '¿Qué cargo desempeña?',
    showValidations: [
      {name: 'relationPep', value: '1', defaultValue: '', isNumber: true},
    ],
    errorMessage: 'Posición requerida',
  },
  externalPanamaResident: {
    name: 'externalPanamaResident',
    label:
      '¿Eres considerado residente fiscal en cualquier otro país diferente a Panamá?',
    errorMessage: 'Campo requerido',
  },
  taxCountries: {
    name: 'taxCountries',
    label: '¿Cuáles son tus países de residencia fiscal?',
    showValidations: [
      {
        name: 'externalPanamaResident',
        value: '1',
        defaultValue: '',
        isNumber: true,
      },
    ],
    errorMessage: 'País requerido',
  },
  taxNumber: {
    name: 'taxNumber',
    label:
      '¿Cuál es tu número de ID Tributario, TIN o SSN de los otros países diferentes a Panamá, donde eres considerado residente fiscal?',
    showValidations: [
      {
        name: 'externalPanamaResident',
        value: '1',
        defaultValue: '',
        isNumber: true,
      },
    ],
    errorMessage: 'Número de ID Tributario, TIN o SSN requerido',
  },
  reason: {
    name: 'reason',
    label: 'Si no tienes ID Tributario, TIN o SSN, indica el motivo',
    showValidations: [
      {
        name: 'externalPanamaResident',
        value: '1',
        defaultValue: '',
        isNumber: true,
      },
      {
        name: 'taxNumber',
        value: '',
        defaultValue: '',
      },
    ],
    errorMessage: 'Motivo requerido',
  },
};

const regex = /^(?!^\s*$)[A-Za-z0-9\sñÑ]+$/;

const validationSchema = Yup.object().shape({
  politicallyExposed: Yup.number()
    .required('PoliticallyExposed is required')
    .default(''),
  position: Yup.string()
    .required(initialData.position.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  relationPep: Yup.number()
    .required('relationPep is required')
    .default(''),
  namePolitically: Yup.string()
    .required(initialData.namePolitically.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  positionPolitically: Yup.string()
    .required(initialData.positionPolitically.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  externalPanamaResident: Yup.number()
    .required('externalPanamaResident is required')
    .default(''),
  taxCountries: Yup.string()
    .required(initialData.taxCountries.errorMessage)
    .default(''),
  taxNumber: Yup.string()
    // .required(initialData.taxNumber.errorMessage)
    // .matches(regex, 'Campos inválidos')
    // .min(1, 'No se permiten espacios en blanco')
    // .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  reason: Yup.string()
    .required(initialData.reason.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
});

const ANSWERTYPE = {
  TEXT: 'text',
  TRUEFALSE: 'truefalse',
};

const formatterAnswerPayload = payload => {
  let _answers = [];
  payload.forEach(group => {
    group?.answers?.length > 0 &&
      group?.answers.forEach(item => {
        const date = new Date();
        const question = item?.field?.label || item?.question;
        const value = item?.field?.value || item?.answer;
        const _createdBy = item?.createdBy ? item?.createdBy : SELLER_ID_TYPE;
        let _answer = '';
        if (item?.typeAnwswer === ANSWERTYPE.TRUEFALSE) {
          _answer = !!+value ? 'si' : 'no';
        }
        if (item?.typeAnwswer === ANSWERTYPE.TEXT) {
          _answer = group?.groupValue ? value : 'No aplica';
        }
        _answers.push({
          id: item.id,
          groups: group.groupName,
          question: question,
          typeAnwswer: item.typeAnwswer,
          answer: _answer,
          createdBy: _createdBy,
          lastModifiedDate: date,
          createdDate: date,
        });
      });
  });
  return _answers;
};

const PoliticalForm = ({verificationStep, setVerificationStep, props}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {Matrix} = useSelector(state => state.KOReducer);
  const [complet, setComplet] = useState(false);
  const {
    countriesBirthTranslate,
    origin,
    configGlobal,
    levelRisk,
    isLexisNexisList,
  } = useSelector(state => state.RequestCardReducer);
  const formRequest = useSelector(state => state.RequestCardReducer.form);

  const {
    formData,
    getValue,
    handleChange,
    handleBlur,
    handleSubmit,
    getVisibility,
    showError,
    getError,
    setOnChangeEvents,
    setInitialValues,
  } = useForm(initialData, validationSchema);

  const setOnChangeEventsForm = useCallback(() => {
    const events = [
      {
        name: 'politicallyExposed',
        onChange: value =>
          dispatch(editFormRequest({isPolitician: parseInt(value)})),
      },
      {
        name: 'position',
        onChange: value => dispatch(editFormRequest({position: value})),
      },
      {
        name: 'relationPep',
        onChange: value =>
          dispatch(editFormRequest({relationPep: parseInt(value)})),
      },
      {
        name: 'namePolitically',
        onChange: value => dispatch(editFormRequest({namePolitically: value})),
      },
      {
        name: 'positionPolitically',
        onChange: value =>
          dispatch(editFormRequest({positionPolitically: value})),
      },
      {
        name: 'externalPanamaResident',
        onChange: value =>
          dispatch(editFormRequest({externalPanamaResident: parseInt(value)})),
      },
      {
        name: 'taxCountries',
        onChange: value => dispatch(editFormRequest({taxCountries: value})),
      },
      {
        name: 'taxNumber',
        onChange: value => dispatch(editFormRequest({taxNumber: value})),
      },
      {
        name: 'reason',
        onChange: value => dispatch(editFormRequest({reason: value})),
      },
    ];
    setOnChangeEvents(events);
  });

  useEffect(() => {
    if (Matrix !== null && levelRisk !== null) {
      const isPep = !!configGlobal || formRequest.isPolitician === 1;
      const isCrs = formRequest.relationPep === 1;
      const value = formRequest.birthdate;
      let age = moment().diff(value, 'years', true);
      let years = Math.trunc(age);
      if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
        if (Matrix.result === 'RECHAZADO') {
          props.goToStep(SCREENS.FinancialError);
        } else if (
          Matrix.result === 'APROBADO' &&
          Matrix.isPep === false &&
          !isPep &&
          !isCrs &&
          levelRisk !== 'ALTO' &&
          years < 65
        ) {
          props.goToStep(SCREENS.ChubbScreen);
        } else if (
          Matrix.result === 'APROBADO' &&
          Matrix.isPep === false &&
          !isPep &&
          !isCrs &&
          levelRisk !== 'ALTO' &&
          years >= 65
        ) {
          props.goToStep(SCREENS.CreditApproved);
        } else if (
          (Matrix.result === 'APROBADO' &&
            Matrix.isPep === true &&
            years < 65) ||
          isPep ||
          isCrs ||
          levelRisk === 'ALTO'
        ) {
          props.goToStep(SCREENS.ChubbScreen);
        } else if (
          (Matrix.result === 'APROBADO' &&
            Matrix.isPep === true &&
            years >= 65) ||
          isPep ||
          isCrs ||
          levelRisk === 'ALTO'
        ) {
          props.goToStep(SCREENS.RequestInRevision);
        }
      }
    }
  }, [Matrix, levelRisk]);

  useEffect(() => {
    updateInitialValues();
    setOnChangeEventsForm();
  }, []);

  useEffect(() => {
    dispatch(countryBirthTranslate());
  }, []);

  const handleContinueClick = async () => {
    await handleSubmit(handleSubmitForm);
  };

  useEffect(() => {
    const isPreapOrPrepago = origin === 'PREAP' || origin === ORIGIN.PREPAGO;
    const isLevelRiskValid = levelRisk !== null;
    const isComplete = complet === true;
    if (isPreapOrPrepago && isLevelRiskValid && isComplete) {
      setLoading(false);
      const value = formRequest.birthdate;
      let age = moment().diff(value, 'years', true);
      let years = Math.trunc(age);
      const isPep = !!configGlobal || formRequest.isPolitician === 1;
      const isCrs = formRequest.relationPep === 1;
      if (origin === 'PREAP' && complet) {
        if (!isPep && !isCrs && years < 65 && levelRisk !== 'ALTO') {
          props.goToStep(SCREENS.ChubbScreen);
        } else if (!isPep && !isCrs && years >= 65 && levelRisk !== 'ALTO') {
          props.goToStep(SCREENS.CreditApproved);
        } else if (years >= 65 && (isPep || isCrs || levelRisk === 'ALTO')) {
          props.goToStep(SCREENS.RequestInRevision);
        } else {
          props.goToStep(SCREENS.ChubbScreen);
        }
      } else if (origin === ORIGIN.PREPAGO && complet) {
        if (!isPep && !isCrs && levelRisk !== 'ALTO' && !isLexisNexisList) {
          props.goToStep(SCREENS.CreditApproved);
        } else if (isPep || isCrs || levelRisk === 'ALTO' || isLexisNexisList) {
          props.goToStep(SCREENS.RequestInRevision);
        }
      }
    }
  }, [origin, levelRisk, complet]);

  const handleSubmitForm = () => {
    try {
      setLoading(true);

      const pep = formRequest.isPolitician;
      const relpep = formRequest.relationPep;
      const crs = formRequest.externalPanamaResident;

      const answer = formatterAnswerPayload([
        {
          groupName: 'PEP',
          groupValue: pep,
          answers: [
            {
              id: 2,
              field: formData.politicallyExposed,
              typeAnwswer: ANSWERTYPE.TRUEFALSE,
            },
            {
              id: 5,
              field: formData.position,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
          ],
        },
        {
          groupName: 'Relacionado PEP',
          groupValue: relpep,
          answers: [
            {
              id: 6,
              field: formData.relationPep,
              typeAnwswer: ANSWERTYPE.TRUEFALSE,
            },
            {
              id: 8,
              field: formData.namePolitically,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
            {
              id: 10,
              field: formData.positionPolitically,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
          ],
        },
        {
          groupName: 'CRS',
          groupValue: crs,
          answers: [
            {
              id: 15,
              field: formData.externalPanamaResident,
              typeAnwswer: ANSWERTYPE.TRUEFALSE,
            },
            {
              id: 17,
              field: formData.taxCountries,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
            {
              id: 19,
              field: formData.taxNumber,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
            {
              id: 20,
              field: formData.reason,
              typeAnwswer: ANSWERTYPE.TEXT,
            },
          ],
        },
      ]);

      const payload = {
        answers: answer || [],
        isCrs: relpep === fieldTypeOptions.YES ? true : false,
        isFatca: crs === fieldTypeOptions.YES ? true : false,
        isPep: pep === fieldTypeOptions.YES ? true : false,
      };

      if (origin === 'PREAP') {
        dispatch(UpdateEndPreap(payload, onSuccess, 'UPDATE_POLITICAL_INFO'));
      } else if (origin === ORIGIN.PREPAGO) {
        dispatch(UpdateEndPrepaid(payload, onSuccess, 'UPDATE_POLITICAL_INFO'));
      } else if (origin === 'CREDI' || origin === 'PRECA') {
        dispatch(UpdateDataMatrix(payload, onSuccess, 'UPDATE_POLITICAL_INFO'));
      }
    } catch (error) {
      setLoading(false);
    }
    // finally {
    //   setLoading(false)
    // }
  };

  async function onSuccess(isSuccess) {
    if (isSuccess && (origin === 'PREAP' || origin === ORIGIN.PREPAGO)) {
      setComplet(isSuccess);
    }
  }

  const handleBackClick = () => {
    setVerificationStep(verificationStep - 1);
  };

  const updateInitialValues = useCallback(() => {
    const initialValues = [
      {
        name: 'politicallyExposed',
        value: formRequest?.isPolitician,
      },
      {
        name: 'position',
        value: formRequest?.position,
      },
      {
        name: 'relationPep',
        value: formRequest?.relationPep,
      },
      {
        name: 'namePolitically',
        value: formRequest?.namePolitically,
      },
      {
        name: 'positionPolitically',
        value: formRequest?.positionPolitically,
      },
      {
        name: 'externalPanamaResident',
        value: formRequest?.externalPanamaResident,
      },
      {
        name: 'taxCountries',
        value: formRequest?.taxCountries,
      },
      {
        name: 'taxNumber',
        value: formRequest?.taxNumber,
      },
      {
        name: 'reason',
        value: formRequest?.reason,
      },
    ];
    setInitialValues(initialValues);
  });
  return (
    <LayoutForm
      hasNextButton={true}
      hasBackButton={true}
      handleSubmit={handleContinueClick}
      handleBackSubmit={handleBackClick}
      loading={loading}
      props={props}>
      {loading && (
        <ModalProcessing
          isOpen={loading}
          headingText="Estamos procesando tu solicitud."
          bodyText={'Aguarda un momento.'}
        />
      )}
      <MyBox
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'column',
          minWidth: '100%',
        }}>
        {loading && (
          <ModalProcessing
            isOpen={loading}
            headingText="Estamos procesando tu solicitud."
            bodyText={'Aguarda un momento.'}
          />
        )}
        <MyBox
          flexDirection={'column'}
          sx={{
            gap: '12px',
          }}>
          <FormControl fullWidth error={showError('politicallyExposed')}>
            <MyBox>
              <MyFormLabel id="demo-row-radio-buttons-group-label">
                {formData.politicallyExposed.label}
                <MyToolTip />
              </MyFormLabel>
            </MyBox>
            <RadioGroup
              row
              id="politicallyExposed"
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={getValue('politicallyExposed')}
              onChange={e => handleChange('politicallyExposed', e.target.value)}
              onBlur={() => handleBlur('politicallyExposed')}>
              <FormControlLabel
                labelPlacement="start"
                value={1}
                data-testid="yes"
                control={<MyRadio />}
                label={<MyOption>Si</MyOption>}
              />
              <FormControlLabel
                labelPlacement="start"
                data-testid="no"
                value={0}
                control={<MyRadio />}
                label={<MyOption>No</MyOption>}
              />
            </RadioGroup>
            {showError('politicallyExposed') && (
              <FormHelperText>
                {initialData.politicallyExposed.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
          {getVisibility('position') && (
            <FormControl fullWidth error={showError('position')}>
              <TextFieldComponent
                error={showError('position')}
                InputProps={{
                  inputProps: {
                    'data-testid': 'position', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                placeholder={formData.position.label}
                value={getValue('position')}
                onChange={e => handleChange('position', e.target.value)}
                onBlur={() => handleBlur('position')}
              />
              {showError('position') && (
                <FormHelperText>{getError('position')}</FormHelperText>
              )}
            </FormControl>
          )}
        </MyBox>
        <MyBox
          flexDirection={'column'}
          sx={{
            gap: '12px',
          }}>
          <FormControl fullWidth error={showError('relationPep')}>
            <MyBox>
              <MyFormLabel id="demo-row-radio-buttons-group-label">
                {formData.relationPep.label}
                <MyToolTipPEP />
              </MyFormLabel>
            </MyBox>
            <RadioGroup
              id="relationPep"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={getValue('relationPep')}
              onChange={e => handleChange('relationPep', e.target.value)}
              onBlur={() => handleBlur('relationPep')}>
              <FormControlLabel
                labelPlacement="start"
                value={1}
                data-testid="yes"
                control={<MyRadio />}
                label={<MyOption>Si</MyOption>}
              />
              <FormControlLabel
                labelPlacement="start"
                value={0}
                data-testid="no"
                control={<MyRadio />}
                label={<MyOption>No</MyOption>}
              />
            </RadioGroup>
            {showError('relationPep') && (
              <FormHelperText>
                {initialData.relationPep.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
          <MyBox>
            {getVisibility('namePolitically') && (
              <FormControl fullWidth error={showError('namePolitically')}>
                <TextFieldComponent
                  error={showError('namePolitically')}
                  placeholder={formData.namePolitically.label}
                  value={getValue('namePolitically')}
                  onChange={e =>
                    handleChange('namePolitically', e.target.value)
                  }
                  InputProps={{
                    inputProps: {
                      'data-testid': 'namePolitically', // Aquí agregamos el data-testid al componente de entrada
                    },
                  }}
                  onBlur={() => handleBlur('namePolitically')}
                />
                {showError('namePolitically') && (
                  <FormHelperText>{getError('namePolitically')}</FormHelperText>
                )}
              </FormControl>
            )}
            {getVisibility('positionPolitically') && (
              <FormControl fullWidth error={showError('positionPolitically')}>
                <TextFieldComponent
                  error={showError('positionPolitically')}
                  InputProps={{
                    inputProps: {
                      'data-testid': 'positionPolitically', // Aquí agregamos el data-testid al componente de entrada
                    },
                  }}
                  placeholder={formData.positionPolitically.label}
                  value={getValue('positionPolitically')}
                  onChange={e =>
                    handleChange('positionPolitically', e.target.value)
                  }
                  onBlur={() => handleBlur('positionPolitically')}
                />
                {showError('positionPolitically') && (
                  <FormHelperText>
                    {getError('positionPolitically')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </MyBox>
        </MyBox>

        <MyBox
          flexDirection={'column'}
          sx={{
            gap: '12px',
          }}>
          <FormControl fullWidth error={showError('externalPanamaResident')}>
            <MyBox>
              <MyFormLabel id="demo-row-radio-buttons-group-label">
                {formData.externalPanamaResident.label}
              </MyFormLabel>
            </MyBox>
            <RadioGroup
              id="externalPanamaResident"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={getValue('externalPanamaResident')}
              onChange={e =>
                handleChange('externalPanamaResident', e.target.value)
              }
              onBlur={() => handleBlur('externalPanamaResident')}
              sx={{
                marginTop: '5px',
              }}>
              <FormControlLabel
                labelPlacement="start"
                data-testid="yes"
                value={1}
                control={<MyRadio />}
                label={<MyOption>Si</MyOption>}
              />
              <FormControlLabel
                labelPlacement="start"
                data-testid="no"
                value={0}
                control={<MyRadio />}
                label={<MyOption>No</MyOption>}
              />
            </RadioGroup>
            {showError('externalPanamaResident') && (
              <FormHelperText>
                {initialData.externalPanamaResident.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </MyBox>
        {getVisibility('taxCountries') && (
          <FormControl fullWidth error={showError('taxCountries')}>
            <MyInputLabel id="demo-simple-select-label">
              {formData.taxCountries.label}
            </MyInputLabel>
            <SelectComponent
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              id="taxCountries"
              IconComponent={ArrowCommon}
              label={formData.taxCountries.label}
              value={getValue('taxCountries')}
              onChange={e => handleChange('taxCountries', e.target.value)}
              onBlur={() => handleBlur('taxCountries')}
              labelId="demo-simple-select-label">
              {countriesBirthTranslate.map((option, index) => (
                <MenuItem key={index} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectComponent>
            {showError('taxCountries') && (
              <FormHelperText>{getError('taxCountries')}</FormHelperText>
            )}
          </FormControl>
        )}
        {getVisibility('taxNumber') && (
          <FormControl fullWidth error={showError('taxNumber')}>
            <TextFieldComponent
              placeholder={formData.taxNumber.label}
              error={showError('taxNumber')}
              InputProps={{
                inputProps: {
                  'data-testid': 'taxNumber', // Aquí agregamos el data-testid al componente de entrada
                },
              }}
              value={getValue('taxNumber')}
              onChange={e => handleChange('taxNumber', e.target.value)}
              onBlur={() => handleBlur('')}
            />
            {showError('taxNumber') && (
              <FormHelperText>{getError('taxNumber')}</FormHelperText>
            )}
          </FormControl>
        )}
        {getVisibility('reason') && (
          <FormControl fullWidth error={showError('reason')}>
            <TextFieldComponent
              error={showError('reason')}
              placeholder={formData.reason.label}
              value={getValue('reason')}
              onChange={e => handleChange('reason', e.target.value)}
              onBlur={() => handleBlur('reason')}
            />
            {showError('reason') && (
              <FormHelperText>{getError('reason')}</FormHelperText>
            )}
          </FormControl>
        )}
      </MyBox>
    </LayoutForm>
  );
};
const MyToolTip = () => {
  let placement = 'right-start';
  //if screen width is less than 1500px, change placement to bottom
  if (window.innerWidth < 1500) {
    placement = 'bottom';
  }
  return (
    <HtmlTooltip
      arrow
      placement={placement}
      title={
        <React.Fragment>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal bold 14px/14px Red Hat Text',
              marginY: '5px',
            }}
            variant="h5">
            PEP:
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal normal 14px/14px Red Hat Text',
            }}
            variant="p">
            Personas nacionales o extranjeras que cumplen funciones públicas
            destacadas de alto nivel o con mando o jurisdicción en un Estado,
            como (pero sin límites) los jefes de Estado o de un gobierno, los
            políticos de alto perfil, los funcionarios gubernamentales,
            judiciales o militares de alta jerarquía, los altos ejecutivos de
            empresas o corporaciones estatales, los funcionarios públicos que
            ocupen cargos de elección popular, entre otros que ejerzan la toma
            de decisiones en las entidades públicas; personas que cumplen o a
            quienes se les han confiado funciones importantes por una
            organización internacional, como los miembros de la alta gerencia,
            es decir, directores, subdirectores y miembros de la junta directiva
            o funciones equivalentes.
          </Typography>
        </React.Fragment>
      }>
      <IconButton>
        <InfoIcon
          sx={{
            color: '#026E18',
          }}
        />
      </IconButton>
    </HtmlTooltip>
  );
};
const MyToolTipPEP = () => {
  let placement = 'right-start';
  //if screen width is less than 1500px, change placement to bottom
  if (window.innerWidth < 1500) {
    placement = 'bottom';
  }
  return (
    <HtmlTooltip
      arrow
      placement={placement}
      title={
        <React.Fragment>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal bold 14px/14px Red Hat Text',
              marginY: '5px',
            }}
            variant="h5">
            Familiares Cercanos:
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal normal 14px/14px Red Hat Text',
            }}
            variant="p">
            Únicamente, el cónyuge, los padres, los hermanos y los hijos de la
            persona expuesta políticamente.
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal bold 14px/14px Red Hat Text',
              marginY: '10px',
            }}
            variant="h5">
            Estrecho Colaborador:
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              lineHeight: '30px',
              font: 'normal normal normal 14px/14px Red Hat Text',
            }}
            variant="p">
            Persona conocida por su íntima relación con respecto a la persona
            expuesta políticamente, esto incluye a quienes están en posición de
            realizar transacciones financieras, comerciales o de cualquier
            naturaleza, ya sean locales e internacionales, en nombre de la
            persona expuesta políticamente.
          </Typography>
        </React.Fragment>
      }>
      <IconButton>
        <InfoIcon
          sx={{
            color: '#026E18',
          }}
        />
      </IconButton>
    </HtmlTooltip>
  );
};
const HtmlTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    maxWidth: '746px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '15px',
  },
}));

export default PoliticalForm;
