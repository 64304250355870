import {notification} from 'antd'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import {format, parseISO} from 'date-fns'
import i18nIsoCountries from 'i18n-iso-countries'
import _ from 'lodash'
import moment from 'moment'
import {API_KEY, Cmf_Base, ENCRYPT_KEY} from '../Data/Api'
import {ELECTRONIC_SCAN_TYPE} from '../components/ElectronicaID'
import {fieldTypeOptions} from '../containers/CardRequest/FormRequest.config'
import {ENVIRONMENT} from '../store/actions/API'
import {COUNTRIES_TYPES} from './Constants'
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'))
// Funcion que permite que los codigos de paises sean convertidos al nombre del paais
// ISO norma 3166-1 alpha-3
export const alphaCode = alpha3Code => {
  const countryName = i18nIsoCountries.getName(alpha3Code, 'es')
  if (countryName) {
    return countryName
  } else {
    return null
  }
}
export const openNotificationWithIcon = (type, messae, time) => {
  notification[type]({
    message: 'Notificación',
    description: messae ?? 'Error',
    duration: time ?? 4.5,
  })
}
//Esta la uso cuando la fecha empieza DD/MM/YYYY
export const dateMonthString = (params = new Date()) => {
  let monthShortNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  let t
  if (typeof params === 'number') {
    t = new Date()
    return params + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear()
  }
  let date = [params] + ''
  let newdate = date
    .split('/')
    .reverse()
    .join('/')
  let new_t = moment(newdate)
  return (
    new_t.date() + '-' + monthShortNames[new_t.month()] + '-' + new_t.year()
  )
}
//Esta se usa cuando viene el formaro aceptado
export const Comun = paramas => {
  var monthShortNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  var t = new Date(paramas)
  return (
    t.getDate() + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear()
  )
}

export const CantidadTotalEnPorcentaje = (paramas1, paramas2) => {
  let porcentaje = (paramas2 / paramas1) * 100
  return porcentaje > 100 ? 100 : porcentaje // Limita el porcentaje a un máximo de 100
  /* paramas1 es el saldo disponible y el paramas 2 es el saldo utilizado */
}
export const disabledDate = current => {
  // Can not select days before today and today
  return current && current > moment().endOf('day')
}
export const Validate = something => {
  return something && something !== undefined
}
export const ValidateEmptyObject = Objct => {
  return Validate(Objct) && Object.keys(Objct).length > 0
}
export const FormatName = (str = '') => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
export const ConsoleHelper = (msg, data) => {
  if (ENVIRONMENT === 'prod') return
  if (msg) {
    console.log(msg, data)
  } else {
    console.log(data)
  }
}
export const FilterData = (data, text, propName1, propName2, propName3) => {
  let FilteredData = []
  if (text === '') {
    FilteredData = data
  } else {
    const filter = text.toUpperCase()
    let results = data.filter(item => {
      return _.includes(item[propName1].toUpperCase(), filter)
    })
    if (results === '' || (results === undefined && propName2)) {
      results = data.filter(item => {
        return _.includes(item[propName2].toUpperCase(), filter)
      })
      if (results === '' || (results === undefined && propName3)) {
        results = data.filter(item => {
          return _.includes(item[propName3].toUpperCase(), filter)
        })
      }
    }
    FilteredData = results
  }
  return FilteredData
}
export const formatLicenceDate = date => {
  let split_date = date.split('/')
  let day = split_date[0]
  let month = split_date[1]
  let year = split_date[2]
  return `${year}-${month}-${day}`
}
export const encrypt = payload => {
  let base_key = ENCRYPT_KEY
  // let payload = { orcmzf };
  let new_key = CryptoJS.enc.Utf8.parse(base_key)
  new_key.words.push(new_key.words[0], new_key.words[1])
  let options = {
    mode: CryptoJS.mode.ECB,
  }
  let textArray = CryptoJS.enc.Utf8.parse(JSON.stringify(payload))
  let encrypted = CryptoJS.TripleDES.encrypt(textArray, new_key, options)
  let base64String = encrypted.toString()
  let encode = encodeURIComponent(base64String)
  return encode
}
export const checkApcIdentity = (current, apc) => {
  const {firstName: currentName, firstSurname: currentLastName} = current
  const {firstName: apcName, lastName: apcLastName} = apc.generals
  let split_apc_name = apcName.split(' ')
  let split_apc_lastname = apcLastName.split(' ')
  if (
    `${currentName + ' ' + currentLastName}` !==
    `${split_apc_name[0] + ' ' + split_apc_lastname[0]}`
  )
    return true
  return false
}
export const CapitalizeString = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
/**
 * Se Utiliza el algoritmo de JaroWrinker que mide la distancia o similitud entre de dos cadena
 * @param {any} s1
 * @param {any} s2
 * @returns {any}
 */
function JaroWrinker(s1, s2) {
  var m = 0
  if (s1.length === 0 || s2.length === 0) {
    return 0
  }
  if (s1 === s2) {
    return 1
  }
  var range = Math.floor(Math.max(s1.length, s2.length) / 2) - 1,
    s1Matches = new Array(s1.length),
    s2Matches = new Array(s2.length)
  for (let i = 0; i < s1.length; i++) {
    var low = i >= range ? i - range : 0,
      high = i + range <= s2.length ? i + range : s2.length - 1
    for (let j = low; j <= high; j++) {
      if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
        ++m
        s1Matches[i] = s2Matches[j] = true
        break
      }
    }
  }
  if (m === 0) {
    return 0
  }
  let n_trans
  var k = (n_trans = 0)
  let j
  for (let i = 0; i < s1.length; i++) {
    if (s1Matches[i] === true) {
      for (j = k; j < s2.length; j++) {
        if (s2Matches[j] === true) {
          k = j + 1
          break
        }
      }
      if (s1[i] !== s2[j]) {
        ++n_trans
      }
    }
  }
  var weight = (m / s1.length + m / s2.length + (m - n_trans / 2) / m) / 3,
    l = 0,
    p = 0.1
  if (weight > 0.7) {
    while (s1[l] === s2[l] && l < 4) {
      ++l
    }
    weight = weight + l * p * (1 - weight)
  }
  return weight
}
/**
 * Orden los resultados de la comparacion de la funcion Coincidencia
 * @param {any} a
 * @param {any} b
 * @returns {any}
 */
function compare(a, b) {
  if (a.jw < b.jw) {
    return 1
  }
  if (a.jw > b.jw) {
    return -1
  }
  return 0
}
/**
 * Description
 * @param {any} datoToCompare string o cadena que se desea comparar
 * @param {any} Catalag Catalogo o cadena contra la que se va a comparar
 * @param {any} levelCoin Nivel de coincidencia entre catalogo CMF y catalogo EID 0.1(Nivel de conincidencia bajo) a 1(Nivel de coincidencia estricto)
 * @returns {any}
 */
export const Coincidencia = (datoToCompare, Catalag, levelCoin) => {
  let sizeArray = Catalag.length
  let response = []
  for (var m = 0; m < sizeArray; m++) {
    var JaroWrinkerValue = JaroWrinker(
      Catalag[m].label
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''),
      datoToCompare.toLowerCase(),
    )
    if (JaroWrinkerValue > levelCoin) {
      let item = Object.assign({jw: JaroWrinkerValue}, Catalag[m])
      response.push(item)
    }
  }
  response.sort(compare)
  return response
}
/**
 * Description
 * @param {any} datoToCompare string o cadena que se desea comparar
 * @param {any} Catalag Catalogo o cadena contra la que se va a comparar
 * @param {any} levelCoin Nivel de coincidencia entre catalogo CMF y catalogo EID 0.1(Nivel de conincidencia bajo) a 1(Nivel de coincidencia estricto)
 * @returns {any}
 */
export const CoincidenciaMap = (datoToCompare, Catalag, levelCoin) => {
  let sizeArray = Catalag.length
  let response = []
  for (var m = 0; m < sizeArray; m++) {
    var JaroWrinkerValue = JaroWrinker(
      Catalag[m].label
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''),
      datoToCompare.toLowerCase(),
    )
    if (JaroWrinkerValue > levelCoin) {
      let item = Object.assign({jw: JaroWrinkerValue}, Catalag[m])
      response.push(item)
    }
  }
  response.sort(compare)
  return response
}
/**
 * Funcion que elimina acentos de palabras(tildes, etc), igual valida si no trae data para que no reviente
 * @param {any} cadena String a normalizar
 * @returns {any}
 */
export const removeAccents = cadena => {
  if (cadena !== undefined) {
    const string = cadena
      .normalize('NFD')
      .replace(/[[]]|['`´‘’.,$^#@`~*+-/[{};:<>"]|[\u0300-\u036f]/g, '')
    // eslint-disable-next-line no-useless-escape
    return string.normalize('NFD').replace(/[&\/\\-]/g, ' ')
  }
}

export const primaryNames = subject => {
  let primaryNames = subject?.primaryName || ''
  const Pnames = []
  const tok = primaryNames.split(' ')
  const SpecialName = [
    'da',
    'de',
    'del',
    'la',
    'las',
    'los',
    'mac',
    'mc',
    'van',
    'von',
    'y',
    'san',
    'santa',
  ]
  let prevN = ''
  tok.forEach(function(name) {
    const nameAux = name.toLowerCase()
    if (SpecialName.indexOf(nameAux) !== -1) {
      prevN += name + ' '
    } else {
      Pnames.push(prevN + name)
      prevN = ''
    }
  })
  return Pnames
}
export const lastNames = subject => {
  let secondaryNames = subject?.secondaryName || ''
  const names = []
  const tokens = secondaryNames.split(' ')
  const SpecialName = [
    'da',
    'de',
    'del',
    'la',
    'las',
    'los',
    'mac',
    'mc',
    'van',
    'von',
    'y',
    'san',
    'santa',
  ]
  let prev = ''
  tokens.forEach(function(name) {
    const nameAux = name.toLowerCase()
    if (SpecialName.indexOf(nameAux) !== -1) {
      prev += name + ' '
    } else {
      names.push(prev + name)
      prev = ''
    }
  })
  return names
}

export const validateMonto = monto => {
  const montoF = monto.toString()
  const match = montoF
    // .match(/^(?:[1-4][0-9]{1,2}|[1-9][0-9]{0,1}|500)$/);
    .match(/^(?:[1-4][0-9]{1,2}|[1-9][0-9]{0,1}|500+\.00\d{0,2}|0+)(\.\d\d)?$/)

  return !!match
}

//Funcion para monedas

export const formatoMoneda = numero => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(numero)
}

// Enmascarado de tarjeta
export const ocultarNumero = number => {
  // Verificamos si el número es una cadena o un número y lo convertimos a cadena
  const numberStr = number?.toString()

  // Obtenemos los últimos 4 dígitos
  const lastFourDigits = numberStr?.slice(-4)

  // Creamos una cadena con 4 asteriscos y los últimos 4 dígitos
  const asteriskString = '**** ' + lastFourDigits

  return asteriskString
}

export const convertirFecha = fechaISO => {
  const fecha = parseISO(fechaISO) // Convierte la fecha ISO a un objeto de fecha
  const formato = 'd MMM. y' // Define el formato deseado

  const fechaFormateada = format(fecha, formato) // Formatea la fecha

  return fechaFormateada
}

export function formatearFecha(fecha, formato) {
  const formatosFecha = [
    'DD/MM/YYYY',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'YYYY-MM-DD HH:mm:ss.SSS',
  ]
  return moment(fecha, formatosFecha, true).format('MM/DD/YYYY')
}

//get cycle current month and year
export const getCurrent = cycle => {
  let now = moment().format('DD-MM-YYYY')
  var newdate = new Date()
  const mesCiclo = moment(newdate).format('MM')
  const yearCiclo = moment(newdate).format('YYYY')
  const datosFecha = cycle + '-' + mesCiclo + '-' + yearCiclo
  const resultado =
    datosFecha < now
      ? datosFecha
      : moment(datosFecha, 'DD-MM-YYYY')
          .subtract(1, 'months')
          .format('DD-MM-YYYY')
  const Mes = moment(resultado, 'DD-MM-YYYY').format('M')
  const Anyo = moment(resultado, 'DD-MM-YYYY').format('Y')

  return {Mes, Anyo}
}

export const getName = texto => {
  var palabras = texto.split(' ')
  var resultado = []

  for (var i = 0; i < palabras.length; i++) {
    var palabra = palabras[i].toLowerCase()
    palabra = palabra.charAt(0).toUpperCase() + palabra.slice(1)
    resultado.push(palabra)
  }

  return resultado.join(' ')
}
export const firstName = texto => {
  var palabras = texto.split(' ')
  if (palabras.length > 0) {
    var primerNombre = palabras[0].toLowerCase()
    primerNombre = primerNombre.charAt(0).toUpperCase() + primerNombre.slice(1)
    return primerNombre
  } else {
    return ''
  }
}

export const Name = texto => {
  // Divide la cadena en palabras usando espacios en blanco como separadores
  const words = texto.split(' ')

  // Verifica si hay al menos una palabra
  if (words.length > 0) {
    // Toma la primera palabra
    const firstWord = words[0]

    // Convierte la primera letra en mayúscula y el resto en minúscula
    const capitalizedFirstWord =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase()

    return capitalizedFirstWord
  }

  // Devuelve una cadena vacía si no se encontraron palabras
  return ''
}
export const uploadFileTest = async (name, fbase64) => {
  try {
    const base64 = `data:@file/jpeg;base64,${fbase64}`
    const blob = await (await fetch(base64)).blob()
    const formData = new FormData()
    const _file = new File([blob], name)
    formData.append('SourceFile', _file)
    formData.append('FileIdentifier', name)

    const headers = {
      Apikey: API_KEY,
      'Content-Type': 'multipart/form-data',
    }

    const response = await axios.post(`${Cmf_Base}api/blob/upload`, formData, {
      headers,
    })

    const file_upload = response?.data?.fileUrl
    return file_upload || false
  } catch (error) {
    console.error('Error uploading file:', error)
    return false
  }
}
export const uploadFileTestPhone = async (name, fbase64) => {
  try {
    const base64 = fbase64
    const blob = await (await fetch(base64)).blob()
    const formData = new FormData()
    const _file = new File([blob], name)
    formData.append('SourceFile', _file)
    formData.append('FileIdentifier', name)

    const headers = {
      Apikey: API_KEY,
      'Content-Type': 'multipart/form-data',
    }

    const response = await axios.post(`${Cmf_Base}api/blob/upload`, formData, {
      headers,
    })

    const file_upload = response?.data?.fileUrl
    return file_upload || false
  } catch (error) {
    console.error('Error uploading file:', error)
    return false
  }
}

export const getNationality = (
  nationality,
  nationalitiesCatalog,
  identityType,
) => {
  try {
    if (identityType === ELECTRONIC_SCAN_TYPE.CEDULA_NACIONAL)
      return COUNTRIES_TYPES.PANAMA
    let filter = nationalitiesCatalog.filter(
      nat => nationality.toLowerCase() === nat.label.toLowerCase(),
    )
    if (filter.length > 0) return filter[0].value
    return null
  } catch (error) {
    return null
  }
}

export const getGender = gender => {
  try {
    const masculin = fieldTypeOptions.MASCULIN
    const femenin = fieldTypeOptions.FEMENIN
    if (gender === 'M') {
      return masculin
    } else if (gender === 'F') {
      return femenin
    }
    return null
  } catch (e) {
    return null
  }
}
export const TransactionTypeDecorator = {
  Pagos: '',
  Envio: '',
  Compra: '-',
  'Pagos - Acreditación por Remesa': '',
  'Reverso de Compra': '',
  D: '-',
  C: '',
  E: '',
  F: '-',
  G: '',
  H: '-',
  I: '',
  J: '-',
}

export const getTransactionTypeDescription = transactionType => {
  switch (transactionType) {
    case 'Pagos':
      return ''
    case 'Envio':
      return ''
    case 'Compra':
      return 'Compras'
    case 'Pagos - Acreditación por Remesa':
      return ''
    case 'Reverso de Compra':
      return ''
    case 'COM':
      return 'Compras'
    case 'PAG':
      return 'Pagos'
    default:
      return transactionType
  }
}

export const ocultarNumeroEnmascarado = number => {
  // Verificamos si el número es una cadena o un número y lo convertimos a cadena
  const numberStr = number?.toString()

  // Obtenemos los últimos 4 dígitos
  const lastFourDigits = numberStr?.slice(-4)

  // Creamos una cadena con 4 asteriscos y los últimos 4 dígitos
  const asteriskString = `**** **** **** ${lastFourDigits}`

  return asteriskString
}

export const formatearFechaVolcan = fecha => {
  // Extraer año, mes y día
  let año = fecha?.substring(0, 4)
  let mes = fecha?.substring(4, 6)
  let día = fecha?.substring(6, 8)

  // Formatear la fecha como MM/DD/YYYY
  var fechaFormateada = mes + '/' + día + '/' + año

  return fechaFormateada
}

export const calculateFutureDate = value => {
  const futureDateValue = moment(value, 'YYYYMMDD')
    .add(1, 'months')
    .format('YYYYMMDD')
  var año = futureDateValue.substring(0, 4)
  var mes = futureDateValue.substring(4, 6)
  var día = futureDateValue.substring(6, 8)

  // Formatear la fecha como MM/DD/YYYY
  var fechaFormateada = mes + '/' + día + '/' + año

  return fechaFormateada
}

export const convertVolcanDate = date => {
  // Create a Moment object from the date
  const momentDate = moment(date)

  // Format the date in the desired format
  const formattedDate = momentDate.format('YYYYMMDD')

  return formattedDate
}

export const subtractMonths = (date, numberOfMonths) => {
  const parsedDate = moment(date)

  // Subtract the specified number of months
  const newDate = parsedDate.subtract(numberOfMonths, 'months')
  const formattedDate = newDate.format('YYYYMMDD')

  return formattedDate
}

export const calculateInitialDate = cycle => {
  // Get the current date
  const now = moment()

  // Get the cycle date and subtract a month if necessary
  const ciclo = moment(cycle, 'DD-MMM-YYYY').isBefore(now)
    ? moment(cycle, 'DD-MMM-YYYY')
    : moment(cycle, 'DD-MMM-YYYY').subtract(1, 'months')
  // Get the year and month from the result
  const year = ciclo.format('YYYY')
  const month = ciclo.format('MM')

  // Create the final date
  const finalDate = `${year}${month}${cycle + 1}`

  // Return the final date
  return finalDate
}
export const reloadInsurancePage = () => {
  setTimeout(() => {
    window.location.reload()
    localStorage.setItem('selectedIndex', 0)
  }, [1000])
}
