import { Divider, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import formats from 'src/utils/formats';

export const Estado = () => {
  const dataCurrent = useSelector(
    state => state.ActiveProductReducer.generalvtc,
  );
  const { loadV, selectedProduct } = useSelector(
    state => state.ActiveProductReducer,
  );

  let fechaOriginal = moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY');
  let fechaModificada = fechaOriginal.subtract(1, 'months').set('date', 23);
  let formattedDate = fechaModificada.format('DD/MM/YYYY');

  return (
    <Box sx={{ width: '100%' }}>
      {loadV ? (
        <Skeleton variant="rectangular" width="100%">
          <div style={{ height: '10rem' }} />
        </Skeleton>
      ) : (
        <Stack spacing={2}>
          {!selectedProduct?.isCorporate ? (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha máxima de pago</Typography>
                <Typography variant="h7">
                  {moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY').format(
                    'DD/MM/YYYY',
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Crédito Disponible</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.DisponibleML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Consumo del mes</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.ConsumoMesML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Número de tarjeta</Typography>
                <Typography variant="h7">{dataCurrent?.Tarjeta}</Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Nombre de la tarjeta</Typography>
                <Typography variant="h7">
                  {selectedProduct?.fullName}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha corte</Typography>
                <Typography variant="h7">{formattedDate}</Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Saldo anterior</Typography>
                <Typography variant="h7">
                  {formats.currency(
                    dataCurrent?.SdoUltCorteMN?.toFixed(2) ?? 0,
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Saldo a corte</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.SaldoFinalML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Pago de contado</Typography>
                <Typography variant="h7">
                  {formats.currency(
                    dataCurrent?.PagocontadoML?.toFixed(2) ?? 0,
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Pago mínimo</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.PagoMinimoML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha máxima de pago</Typography>
                <Typography variant="h7">
                  {moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY').format(
                    'DD/MM/YYYY',
                  )}
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </Stack>
      )}
    </Box>
  );
};
export default Estado;
