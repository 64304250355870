import { message } from 'antd';
import axios from 'axios';
import { Cmf_Base, ExtraFinanApi, movementApi } from '../../Data/Api';
import { openNotificationWithIcon } from '../../utils/Functions';
export const movementStatus = (index, anyo = 0, mes = 0) => {
  return async (dispatch, getState) => {
    const { ActiveProductReducer, AuthenticationReducer } = getState();
    const { products } = ActiveProductReducer;
    const { CurrentUser } = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({ type: 'MOVEMENT_STATUS_SHOW' });
    dispatch({ type: 'MOVEMENT_STATUS_CLEAN' });
    try {
      const response = await axios.get(`${Cmf_Base}${movementApi}`, {
        params: {
          anyo,
          mes,
          cuenta: products && products[0].productAccount,
          CreatedBy: products && products[0].productAccount,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data, status } = response;
      if (status && status === 200 && data) {
        dispatch({
          type: 'MOVEMENT_STATUS_SUCCESS',
          payload: JSON.parse(data.jsonAnswer),
        });
      } else {
        message.error('statusText');
      }
    } catch (error) {
      dispatch({ type: 'MOVEMENT_STATUS_ERROR', payload: error });
      openNotificationWithIcon(
        'error',
        error.response.data.problemPublic
          ? error.response.data.problemPublic
          : 'Error al obtener los movimientos',
      );
    } finally {
      dispatch({ type: 'MOVEMENT_STATUS_HIDE' });
    }
  };
};
/**
 * Meotodo para obtener los planes de intrafinanciamiento del producto
 * @param {*} index
 * @param {*} anyo
 * @param {*} mes
 * @returns
 */
export const intrafinanciamiento = (index = 0, anyo = 0, mes = 0) => {
  return async (dispatch, getState) => {
    const { ActiveProductReducer, AuthenticationReducer } = getState();
    const { products } = ActiveProductReducer;
    const { CurrentUser } = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;

    dispatch({ type: 'EXTRAF_STATUS_SHOW' });
    dispatch({ type: 'EXTRAF_STATUS_CLEAN' });

    try {
      const response = await axios.get(`${Cmf_Base}${ExtraFinanApi}`, {
        params: {
          anyo,
          mes,
          cuenta: products && products[index].productAccount,
          CreatedBy: products && products[index].productAccount,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data, status } = response;
      if (status && status === 200 && data) {
        dispatch({
          type: 'EXTRAF_STATUS_SUCCESS',
          payload: JSON.parse(data.jsonAnswer),
        });
      } else {
        message.error('statusText');
      }
    } catch (error) {
      dispatch({ type: 'EXTRAF_STATUS_ERROR', payload: error });
    } finally {
      dispatch({ type: 'EXTRAF_STATUS_HIDE' });
    }
  };
};

export const resetMovementReduce = () => {
  return async dispatch => {
    dispatch({
      type: 'MOVEMENT_STATUS_SUCCESS',
      payload: [],
    });
  };
};
