import { Box, Typography } from '@mui/material'
import * as React from 'react'
import styled, { css } from 'styled-components'
import CheckboxIcon from '../../assets/icons/cmf_linea/svgs/checkbox'
import CheckboxStarIcon from '../../assets/icons/cmf_linea/svgs/checkbox-star'
import Card from '../../assets/icons/nabvar/credit_card.svg'
// import {Checkbox} from 'antd'
import Checkbox from '@mui/material/Checkbox'

const StyleBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1.6px solid #d0d5dd !important;
  cursor: pointer;
  height: 80px;
  ${({isSelected}) =>
    isSelected &&
    css`
      display: flex;
      padding: 1px;
      align-items: center;
      align-self: stretch;
      background: var(--border-specific-light-stroke-activate)#93D500;
      border: transparent;
      position: relative;
    `}
  ${({step}) =>
    step &&
    css`
      height: 56px;
    `}
  ${({isDisabled}) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`

const ContainerBox = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  background: var(--specific-light-content-white);
  width: 100%;
  height: 100%;
  ${({isSelected}) =>
    isSelected &&
    css`
      display: flex;
      padding: 16px;
      justify-content: space-between;
      align-self: stretch;
      border-radius: 16px;
      flex-direction: row;
      align-items: center;
      background: var(--background-specific-light-stroke-activate) !important;
      width: 100%;
      height: 100%;
    `}
  ${({isDisabled}) =>
    isDisabled &&
    css`
      background: #d5d5d5;
    `}
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`

const Icon = styled.div`
  border-radius: var(--br-33xl);
  background-color: var(--specific-light-content-white);
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px;
`

const NotificationsIcon = styled.img`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`

const Text2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`

const StyledCheckbox = styled(Checkbox)(({step}) => ({
  width: '18px',
  height: '18px',
  borderColor: '#101828CC',
  '& .MuiSvgIcon-root': {
    borderRadius: 6,
  },
  '& .ant-checkbox-inner': {
    backgroundColor: '#fff',
    borderColor: step ? '#55ac00' : '#101828CC',
  },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: step ? '#55ac00' : '#011e41',
    borderColor: step ? '#55ac00' : '#011e41',
  },
  '&:hover .ant-checkbox-inner': {
    backgroundColor: step ? '#55ac00' : '#011e41',
    borderColor: step ? '#55ac00' : '#011e41',
  },
}))

export const ButtonCheckbox = ({
  title,
  style,
  subtitle,
  icon,
  iconStyle,
  isSelected,
  highlight,
  onCheckboxChange,
  step,
  isDisabled,
  color,
}) => {
  const handleStyleBoxClick = () => {
    onCheckboxChange(!isSelected)
  }
  return (
    <StyleBox
      isDisabled={isDisabled}
      step={step}
      isSelected={isSelected}
      style={
        highlight
          ? {
              background:
                'var(--specific-light-semantic-brand-secondary-azul-gradient), var(--specific-primary)',
            }
          : null
      }
      onClick={handleStyleBoxClick}>
      <ContainerBox
        style={{
          ...style,
          background: isDisabled
            ? '#d5d5d5'
            : highlight
            ? 'transparent'
            : isSelected
            ? 'var(--specific-light-background-verde)'
            : 'var(--specific-light-content-white)',
        }}>
        <Info>
          {icon ? (
            <Icon
              style={{
                ...iconStyle,
                'background-color': isDisabled
                  ? '#d5d5d5'
                  : highlight
                  ? 'rgba(152, 162, 179, 0.24)'
                  : isSelected
                  ? 'var(--specific-light-content-white)'
                  : 'var(--specific-light-background-verde)',
              }}>
              {icon ?? <NotificationsIcon alt="" src={Card} />}
            </Icon>
          ) : null}

          <Text2>
            <Typography variant="H5">{title}</Typography>
            <Typography variant="subtitleBanner">{subtitle}</Typography>
          </Text2>
        </Info>
        <StyledCheckbox
          checked={isSelected}
          icon={
            highlight ? (
              <CheckboxStarIcon checked={false} />
            ) : (
              <CheckboxIcon checked={false} color={color} />
            )
          }
          checkedIcon={
            highlight ? (
              <CheckboxStarIcon checked={true} />
            ) : (
              <CheckboxIcon checked={true} color={color} />
            )
          }
        />
      </ContainerBox>
    </StyleBox>
  )
}
