import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, Typography, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import fondoLoginCMF from '../../../assets/images/cmf_linea/fondoLoginCMF.webp';
import logoCMF from '../../../assets/images/cmf_linea/logoCMF.svg';
import mockup from '../../../assets/images/cmf_linea/mockup.webp';
import {
  LoginAuthentication,
  ResetPasswordSendEmail,
} from '../../../store/actions/LoginAction';
import { messageCMF } from '../../../theme/constants';
import * as container from './login.cmf.style';

import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { store } from 'src/config/configStore';

const validationSchema = yup.object({
  email: yup
    .string('Introduce tu correo electrónico')
    .email('Introduzca un correo electrónico válido')
    .required('Campo requerido'),
  password: yup
    .string('Ingresa tu contraseña')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Campo requerido'),
});

const RedditTextField = styled(props => <TextField {...props} />)(
  ({ theme }) => ({
    '& .MuiInputAdornment-root': {
      marginTop: '0!important',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      left: '30px',
    },
    '& .MuiInputBase-input-MuiFilledInput-input': {
      paddingLeft: '3px !important',
    },

    '& .MuiInputLabel-root': {
      position: 'absolute',
      left: '30px',
      marginLeft: '14px',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '16px',
      border: '1px solid var(--specific-light-stroke-l, #D0D5DD)',
      background: 'var(--specific-light-background-white, #FFF)',
      overflow: 'hidden',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
      },
    },
  }),
);
function LoginForm({ route, email }) {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { Authenticated, emailUser, ResetLoading } = useSelector(
    state => state.AuthenticationReducer,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ResetPasswordSendEmail(emailUser, onSuccess));
  };

  window.addEventListener('beforeunload', () => {
    if (route !== undefined) {
      store.dispatch({ type: 'RESET_LOGIN' });
    }
  });
  const onSuccess = success => {
    if (success) {
      navigate('/reset-password');
    }
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const { LoadingAuth } = useSelector(state => state.AuthenticationReducer);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  useEffect(() => {
    navigate('/');
  }, []);

  useEffect(() => {
    if (Authenticated === 3) {
      setOpen(true);
    }
  }, [Authenticated]);

  const navigate = useNavigate();

  const goToForgot = () => {
    navigate('/reset-password');
  };

  useEffect(() => {
    if (route !== 'reset') {
      dispatch({ type: 'RESET_LOGIN' });
    }
  }, [route]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(LoginAuthentication(values));
    },
  });

  return (
    <container.Containt>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}>
        <DialogTitle id="alert-dialog-title">
          {'Actualización de contraseña'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Por razones de seguridad, te pedimos que actualices tu contraseña.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={ResetLoading}
            variant="contained"
            onClick={handleClose}
            autoFocus>
            Aceptar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <container.LoginContainer>
        <container.LoginDescription>
          <img src={logoCMF} alt="Logo" />
        </container.LoginDescription>
        <container.formCMF>
          <container.FormL>
            <form onSubmit={formik.handleSubmit}>
              <container.text>
                <Typography variant="titleLogin">Iniciar sesión</Typography>
              </container.text>
              <Typography variant="textGray">
                ¡Bienvenido! Por favor, ingrese sus datos
              </Typography>
              <container.divFiel>
                <RedditTextField
                  fullWidth
                  label="Correo electrónico"
                  id="email-input"
                  data-testid="email"
                  variant="filled"
                  style={{ marginTop: 11 }}
                  placeholder="nombre@gmail.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon
                          fontSize="small"
                          sx={{
                            color:
                              'var(--specific-light-semantic-brand-secondary)',
                            margin: '0px 8px',
                          }}
                        />
                      </InputAdornment>
                    ),

                    disableUnderline: true,
                  }}
                />
              </container.divFiel>

              <container.divFiel>
                <RedditTextField
                  fullWidth
                  label="Contraseña"
                  id="password-input"
                  data-testid="password"
                  variant="filled"
                  style={{ marginTop: 11 }}
                  placeholder="***********"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsIcon
                          fontSize="small"
                          sx={{
                            color:
                              'var(--specific-light-semantic-brand-secondary)',
                            margin: '0px 8px',
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? (
                            <Visibility
                              style={{ color: '#026E18', margin: '7px' }}
                            />
                          ) : (
                            <VisibilityOff
                              style={{ color: '#026E18', margin: '7px' }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </container.divFiel>

              <div
                className="Text-info"
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                }}>
                <container.aReset onClick={goToForgot}>
                  Crear / Cambiar mi contraseña
                </container.aReset>
              </div>
              <container.buttonContainer>
                <container.buttonLogin
                  variant="contained"
                  type="submit"
                  data-testid="submit"
                  id="login-button"
                  loading={LoadingAuth}>
                  Iniciar sesión
                </container.buttonLogin>
              </container.buttonContainer>
            </form>
          </container.FormL>
        </container.formCMF>
        <container.divCopy>
          <container.CopyContainer>
            <container.pCopy>{messageCMF.copyright}</container.pCopy>
          </container.CopyContainer>
        </container.divCopy>
      </container.LoginContainer>
      <container.imageCMF>
        <img
          src={fondoLoginCMF}
          style={{
            width: '45vw',
            height: '100vh',
            objectFit: 'cover',
            objectPosition: 'right bottom',
          }}
          alt="Background"
        />
        <container.MockupContainer>
          <img
            src={mockup}
            style={{
              width: '45vw',
            }}
            alt="Background"
          />
        </container.MockupContainer>
      </container.imageCMF>
    </container.Containt>
  );
}

const Login = LoginForm;

export default Login;
