import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  formatearFecha,
  formatoMoneda,
  getTransactionTypeDescription,
} from 'src/utils/Functions';
import styled from 'styled-components';
import { ObtenerIcono } from './Movimientos';
const DatosDeLa = styled.div`
  position: relative;
  line-height: 1.13rem;
  font-weight: 500;
`
const Icon = styled.div`
  border-radius: 52px;
  background-color: var(--specific-light-background-verde);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
`
const TitleTime = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const ListContent = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const Text11 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
`
const Description1 = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--detail-s-size);
  line-height: 1rem;
  color: var(--specific-light-content-54);
  text-align: right;
`
const Number1 = styled.div`
  flex: 1;
  width: 8.5rem;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`
const TarjetasDatos = ({data}) => {
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer)
  return (
    <Box
      sx={{
        alignSelf: 'stretch',
        borderRadius: '20px 20px 0 0',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        color: 'var(--specific-light-content-80)',
        background: '#FFFFFF',
        [BreakPointTheme.breakpoints.down('sm')]: {
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '1rem',
          gap: '16px',
          color: 'var(--specific-light-content-80)',
        },
      }}>
      <Text11>
        <Icon>{ObtenerIcono(data[0]?.Transaccion)}</Icon>
        <ListContent>
          <TitleTime>
            <Typography variant="H6">{data[0]?.Descripcion}</Typography>
          </TitleTime>
          <Typography variant="H4">
            {getTransactionTypeDescription(data[0]?.Transaccion)}
          </Typography>
        </ListContent>
      </Text11>
      <Number1>
        <DatosDeLa>{formatoMoneda(data[0]?.MtoTra)}</DatosDeLa>
        <Description1>{formatearFecha(data[0]?.FechaOrigen)}</Description1>
      </Number1>
    </Box>
  )
}

export default TarjetasDatos
